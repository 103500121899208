import styled from 'styled-components';
import device from '../../device';

export const ReplenishmentOperation = styled.div`
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.grayBg};
`;

export const ReplenishmentTitle = styled.div`
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${props => props.theme.blackText};
  margin-bottom: 5px;

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const ReplenishmentValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 46px;
  line-height: 56px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: ${props => props.theme.blackText};

  div:last-child {
    margin-left: 5px;

    svg {
      width: 40px;
      height: 45px;
    }

    span {
      font-weight: 500;
      font-size: 46px;
      line-height: 56px;
    }
  }

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 29px;

    div:last-child {
      svg {
        width: 25px;
        height: 30px;
      }

      span {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
`;

export const ReplenishmentSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background: ${props => props.theme.fullBlackText};
  margin-right: 5px;
`;

export const ReplenishmentInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: ${props => props.theme.blackText};
  & div {
    display: flex;
    align-items: center;
    white-space: pre;
  }

  @media (max-width: 520px) {
    &:first-child {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const Network = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #ff0000;

  @media (max-width: 520px) {
    margin-left: 8px;
  }
`;
