import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import device from 'device';
import { isEmpty } from 'ramda';
import { getUserBalances } from 'utils/services/request/wallets';
import { getOrdersDetails } from 'utils/services/request/operations';
import { useAxiosState, useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import usePersistedState from 'utils/hooks/usePersistedState';
import { isCrypto } from 'utils/crypto';
import Wallets from './Wallets';
import WalletHistory from './WalletHistory';
import { Loader } from '../styled-ui';

const Wrapper = styled.div`
  padding: 80px 268px 0 268px;

  @media ${device.laptopL} {
    padding: 70px 90px 0 90px;
  }

  @media ${device.laptop} {
    padding: 50px 20px 0 20px;
  }
`;

const WalletsNotFound = styled.h4`
  width: max-content;
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;

const HistoryLoaderWrapper = styled.div`
  margin-top: 40px;
`;

const WbWallet = ({ t }) => {
  const [balances, setBalances] = usePersistedState('balances', []);
  const [history, setHistory] = usePersistedState('balancesHistory', []);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const [currencyValue, setCurrencyValue] = useState('');
  const [pagination, setPagination] = useState({});
  const [historyTablePage, setHistoryTablePage] = useState(0);
  const orderOperation = useAxiosState(getOrdersDetails);

  const getBalances = useAxiosState(
    balances?.length > 0
      ? balances
      : getUserBalances({ page: 0, currency: null })
  );

  const getHistory = useAxiosSubmitingEffect(
    history?.length > 0 && history.currency === currencyValue
      ? history
      : getUserBalances({ page: historyTablePage })
  );

  useEffect(() => {
    if (getBalances?.data?.balances) {
      setBalances(getBalances?.data?.balances);
    }
  }, [getBalances]);

  const fetchWalletHistory = currency => {
    getHistory.setFormAndSubmit({
      values: { historyFilter: { currency } },
    });
  };

  const handleShowWalletHistory = currency => {
    if (currency) {
      setCurrencyValue(currency);
    }
    if (currencyValue !== currency || !isShowHistory) {
      fetchWalletHistory(currency);
    }
    if (currency === currencyValue) {
      setIsShowHistory(prev => !prev);
    } else {
      setIsShowHistory(true);
    }
  };

  useEffect(() => {
    if (getHistory?.data?.history) {
      setHistory(getHistory.data.history.content);
      setPagination({
        totalPages: getHistory.data.history.totalPages,
        totalElements: getHistory.data.history.totalElements,
      });
    }
  }, [getHistory]);

  const onPageChange = page => {
    setHistoryTablePage(page - 1);
    fetchWalletHistory(currencyValue);
  };

  return (
    <Wrapper>
      {!isEmpty(balances) && (
        <Wallets
          t={t}
          balances={balances.filter(({ currency }) => isCrypto(currency))}
          operationStatus={orderOperation?.data?.status}
          handleShowWalletHistory={handleShowWalletHistory}
        />
      )}
      {!getBalances.loaded && getBalances.fetching && <Loader />}
      {isEmpty(balances) && getBalances.loaded && !getBalances.fetching && (
        <WalletsNotFound>{t('profile.wbWalletTab.notFound')}</WalletsNotFound>
      )}
      {!getHistory.loaded && getHistory.fetching && (
        <HistoryLoaderWrapper>
          <Loader />
        </HistoryLoaderWrapper>
      )}
      {isShowHistory && (
        <WalletHistory
          t={t}
          history={history}
          pagination={pagination}
          onPageChange={onPageChange}
          currencyValue={currencyValue}
        />
      )}
    </Wrapper>
  );
};

export default WbWallet;
