// eslint-disable-next-line import/prefer-default-export
export const getOrdersHistoryByUser = ({ page = 0 }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/current/paged?page=${page}&size=10&sort=creationDate,desc`,
  data: {},
});

export const getOrdersDetails = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/current`,
};

export const getCryptoCalculation = (inputValue, currencyValue) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ADMIN_URL}/crypto/calculation`,
  data: {
    calculation: {
      inputAsset: inputValue,
    },
    currency: currencyValue,
  },
});

export const getOrdersOutput = (
  inputAsset,
  currency,
  externalCryptoAddress
) => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/crypto/withdrawal`,
  data: {
    calculation: {
      inputAsset,
    },
    currency,
    externalCryptoAddress,
  },
});
export const getDepositOrders = {
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/crypto/deposit`,
};
