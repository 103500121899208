import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import CurrenciesContext from 'utils/contexts/Currencies';
import getLabelByCurrency from 'utils/form/getLabelByCurrency';
import useMobileSize from 'utils/hooks/useMobileSize';
import { getNetworkByCurrency, isCrypto } from 'utils/crypto';
import { numberWithSpaces } from 'utils/number';
import { OUTPUT, EXCHANGE, REPLENISHMENT } from 'utils/constants/exchangeTabs';
import { CRYPTO_CURRENCIES } from 'utils/constant';

import {
  StyledTable,
  TBody,
  TH,
  TD,
  Network,
  HistoryLink,
  Total,
  Button,
  OperationProgress,
  StyledTableMobile,
  TableMobileHead,
  TableMobileBody,
  MobileBodyContent,
  ButtonBlockMobile,
  OperationProgressMobile,
  CurrencyRowContent,
  TotalMobile,
} from './styled-ui';

const Wallets = ({ balances, t, handleShowWalletHistory, operationStatus }) => {
  const currencies = useContext(CurrenciesContext);
  const history = useHistory();
  const isMobile = useMobileSize();
  const network = currency =>
    currency === CRYPTO_CURRENCIES.USDT ||
    currency === CRYPTO_CURRENCIES.USDT_TRC
      ? CRYPTO_CURRENCIES.USDT
      : getNetworkByCurrency(currencies?.data?.cryptoCurrencies, currency);

  const operationProgressText = (amount, currency) =>
    `Идет операция ${
      amount > 0 ? 'пополнения' : 'списания'
    } на ${numberWithSpaces(Math.abs(amount))} ${currency}`;

  const handleClickToRedirect = (currency, tab) => {
    if (isCrypto(localStorage.getItem('from_currency'))) {
      localStorage.setItem('from_currency', currency);
    } else {
      localStorage.setItem('to_currency', currency);
    }
    history.push(`/?tab=${tab}`);
  };

  return isMobile ? (
    <StyledTableMobile>
      <TableMobileHead>
        <div>{t('profile.wbWalletTab.walletsTable.headers.currency')}</div>
        <div>{t('profile.wbWalletTab.walletsTable.headers.total')}</div>
      </TableMobileHead>
      {balances.map(({ currency, amount, processingBalance }) => (
        <TableMobileBody key={currency}>
          <MobileBodyContent>
            <CurrencyRowContent>
              {getLabelByCurrency(currency)}
            </CurrencyRowContent>
            <HistoryLink onClick={() => handleShowWalletHistory(currency)}>
              {t('profile.wbWalletTab.walletsTable.historyLink')}
            </HistoryLink>
            <TotalMobile>{amount}</TotalMobile>
          </MobileBodyContent>
          {processingBalance ? (
            <OperationProgressMobile>
              {operationProgressText(processingBalance.changedAmount, currency)}
            </OperationProgressMobile>
          ) : (
            <ButtonBlockMobile>
              <Button
                onClick={() => handleClickToRedirect(currency, REPLENISHMENT)}
                type="button"
                disabled={!isNil(operationStatus)}
              >
                {t('profile.wbWalletTab.walletsTable.replenishmentBtn')}
              </Button>
              <Button
                onClick={() => handleClickToRedirect(currency, OUTPUT)}
                type="button"
                disabled={!isNil(operationStatus)}
              >
                {t('profile.wbWalletTab.walletsTable.outputBtn')}
              </Button>
              <Button
                onClick={() => handleClickToRedirect(currency, EXCHANGE)}
                type="button"
                disabled={!isNil(operationStatus)}
              >
                {t('profile.wbWalletTab.walletsTable.exchangeBtn')}
              </Button>
            </ButtonBlockMobile>
          )}
        </TableMobileBody>
      ))}
    </StyledTableMobile>
  ) : (
    <StyledTable>
      <thead>
        <tr>
          <TH>{t('profile.wbWalletTab.walletsTable.headers.currency')}</TH>
          <TH>{t('profile.wbWalletTab.walletsTable.headers.total')}</TH>
          <TH>{t('profile.wbWalletTab.walletsTable.headers.actions')}</TH>
        </tr>
      </thead>
      <TBody>
        {balances.map(({ currency, amount, processingBalance }) => (
          <tr key={currency}>
            <TD>
              <CurrencyRowContent>
                {getLabelByCurrency(currency)}
                {network(currency) && <Network>{network(currency)}</Network>}
              </CurrencyRowContent>
              <HistoryLink onClick={() => handleShowWalletHistory(currency)}>
                {t('profile.wbWalletTab.walletsTable.historyLink')}
              </HistoryLink>
            </TD>
            <Total>{numberWithSpaces(amount)}</Total>
            {processingBalance ? (
              <OperationProgress>
                {operationProgressText(
                  processingBalance.changedAmount,
                  currency
                )}
              </OperationProgress>
            ) : (
              <TD>
                <Button
                  onClick={() => handleClickToRedirect(currency, REPLENISHMENT)}
                  type="button"
                  disabled={!isNil(operationStatus)}
                >
                  {t('profile.wbWalletTab.walletsTable.replenishmentBtn')}
                </Button>
                <Button
                  onClick={() => handleClickToRedirect(currency, OUTPUT)}
                  type="button"
                  disabled={!isNil(operationStatus)}
                >
                  {t('profile.wbWalletTab.walletsTable.outputBtn')}
                </Button>
                <Button
                  onClick={() => handleClickToRedirect(currency, EXCHANGE)}
                  type="button"
                  disabled={!isNil(operationStatus)}
                >
                  {t('profile.wbWalletTab.walletsTable.exchangeBtn')}
                </Button>
              </TD>
            )}
          </tr>
        ))}
      </TBody>
    </StyledTable>
  );
};

export default Wallets;
