import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'utils/location';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import ClientContext from 'utils/contexts/Client';
import UserContext from 'utils/contexts/User';
import ViewSwitcher from 'components/Auth/UserProfile/ViewSwitcher';
import Settings from 'components/Auth/UserProfile/Settings';
import OperationsHistoryTable from 'components/Auth/UserProfile/OperationsHistoryTable';
import PaymentMethodList from 'components/Auth/UserProfile/PaymentMethodList';
import WbWallet from 'components/Auth/UserProfile/WbWallet';
import NotificationPage from 'components/NotificationPopup/NotificationPage';
import ReferralProgram from 'components/Auth/UserProfile/ReferralProgram';
import { pushAnalyticsEvent } from 'components/App/analytics';
import { getUserRole } from 'utils/services/selectors/user';
import {
  SETTINGS,
  OPERATION_HISTORY,
  PAYMENT_METHODS,
  PROFILE_TABS,
  NOTIFICATIONS,
  WALLETS,
  EXCHANGE,
  REFERRAL_PROGRAM,
} from 'utils/constants/profileTabs';
import { HOME } from 'constants/routings';
import PROFILE_TABS_KEYS from 'utils/constants/profileTabsKeys';
import USER_ROLES from 'utils/constants/userRoles';

import {
  ProfileWrapper,
  ProfileView,
  OperationWrapper,
  LoaderWrapper,
  Loader,
} from './styled-ui';

const UserProfile = ({ t }) => {
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const [pageView, setPageView] = useState('');
  const history = useHistory();
  const query = useQuery();
  const defaultTabQuery = query.get('tab');
  const isDisplayWbWallet = client?.data?.internalBalanceEnabled;
  const isUserVerified = ['VERIFIED', 'FROZEN'].includes(
    pathOr('', ['data', 'status'], client)
  );
  const isDefaultUser = getUserRole(user, USER_ROLES.USER);
  const isClientLoaded = client?.loaded && !client?.fetching;
  const isDisplaySwitcher = isClientLoaded || !isDefaultUser;
  const isLoading = !client?.loaded && client?.fetching;

  const getProfileTabsByUserStatus = () => {
    switch (true) {
      case !isUserVerified:
        return [SETTINGS, NOTIFICATIONS, REFERRAL_PROGRAM];
      case isUserVerified && !isDisplayWbWallet:
        return PROFILE_TABS.filter(tab => tab !== WALLETS);
      default:
        return PROFILE_TABS;
    }
  };

  const profileTabs = getProfileTabsByUserStatus();

  useEffect(() => {
    window.onbeforeunload = () => {
      PROFILE_TABS_KEYS.forEach(key => localStorage.removeItem(key));
    };
    return () => {
      PROFILE_TABS_KEYS.forEach(key => localStorage.removeItem(key));
    };
  }, []);

  useEffect(() => {
    if (profileTabs.includes(defaultTabQuery)) {
      setPageView(defaultTabQuery);
    }
  }, [defaultTabQuery, profileTabs]);

  const changeTabSettings = tab => {
    if (tab === EXCHANGE) {
      localStorage.removeItem('tab');
      history.push(HOME);
      pushAnalyticsEvent('button_exchange_click');
    } else
      history.push({
        search: `?tab=${tab}`,
      });
    setPageView(tab);
  };

  return (
    <ProfileWrapper>
      {isDisplaySwitcher && (
        <ViewSwitcher
          t={t}
          value={pageView}
          setPageView={changeTabSettings}
          profileTabs={profileTabs}
          isDisplayWbWallet={isDisplayWbWallet}
        />
      )}
      <ProfileView>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {(client?.loaded || !isDefaultUser) && (
          <>
            {pageView === WALLETS && <WbWallet t={t} />}
            {pageView === SETTINGS && <Settings t={t} />}
            {pageView === OPERATION_HISTORY && (
              <OperationWrapper>
                <OperationsHistoryTable t={t} />
              </OperationWrapper>
            )}
            {pageView === PAYMENT_METHODS && (
              <OperationWrapper>
                <PaymentMethodList t={t} />
              </OperationWrapper>
            )}
            {pageView === NOTIFICATIONS && (
              <OperationWrapper>
                <NotificationPage t={t} />
              </OperationWrapper>
            )}
            {pageView === REFERRAL_PROGRAM && (
              <OperationWrapper>
                <ReferralProgram isUserVerified={isUserVerified} t={t} />
              </OperationWrapper>
            )}
          </>
        )}
      </ProfileView>
    </ProfileWrapper>
  );
};

export default UserProfile;
