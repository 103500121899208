// eslint-disable-next-line import/prefer-default-export
export const getUserBalances = ({ page = 0, currency }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ACCOUNTING_URL}/account/balance?page=${page}&size=20&sort=created_timestamp,desc`,
  data: {
    historyFilter: { currency },
  },
});

export const getCurrencyBalance = currency => ({
  method: 'get',
  url: `${process.env.REACT_APP_ACCOUNTING_URL}/account/balance/${currency}`,
});
