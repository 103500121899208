export const HOME = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const CONFIRM = '/confirm';
export const VERIFICATIONS = '/verifications';
export const LOGOUT = '/logout';
export const SUPPORT = '/support';
export const FAQ = '/faq';
export const MEDIA = '/media';
export const MEDIA_VIEW = '/media/:mediaId';
export const DISPUTE = '/dispute';
export const BELARUSBANK =
  '/media/poluchite-vygodnyj-kurs-s-kartami-belarusbank-visa';
export const RESTORE_PASSWORD = '/restore-password';
export const USER_PROFILE = '/profile';
export const TRANSFER = '/transfer';
export const AUTHORIZE = '/authorize';
export const ATTACH_CARD = '/attach-card';
export const INFORMATION = '/information';
