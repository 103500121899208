import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form } from 'formik';
import { isEmpty, pathOr, isNil } from 'ramda';
import requestErrors from 'utils/constants/requestErrors';
import { WALLETS } from 'utils/constants/profileTabs';
import CurrenciesContext from 'utils/contexts/Currencies';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getNetworkByCurrency, getCurrencyValue } from 'utils/crypto';
import { getCurrencyBalance } from 'utils/services/request/wallets';
import { getCryptoCalculation } from 'utils/services/request/operations';
import { getClientTestingNeeded } from 'utils/user';
import { fromLocaleNumber } from 'utils/number';
import DropdownInput from 'ui-kit/DropdownInput';
import LimitRateError from 'components/Exchanger/LimitRateError';
import LimitRate from 'components/Exchanger/LimitRate';
import SubmitButton from 'components/Exchanger/SubmitButton';
import { getCurrencyLabel } from 'components/Exchanger/ExchangerCalculator';
import AdditionalChargeRow from 'components/Exchanger/AdditionalChargeRow';
import Input from 'components/Exchanger/Input';

import {
  ExchangerWrapper,
  ExchangerWrapperAdditional,
  HeaderCalculationWrapper,
  OutputMainContainer,
  OutputNetworkContainer,
  OutputAddressContainer,
  WbWalletBtn,
  LabelInput,
  OutputList,
  OutputItem,
  OutputItemInfo,
  SystemInfoError,
  ErrorMessage,
  InputWrapper,
  Container,
} from '../Exchanger/styled-ui';

const DEBOUNCE_TIMER = 400;

const OutputCalculator = ({
  handleBlur,
  values: {
    calculation: { inputAsset },
    currency,
    externalCryptoAddress,
  },
  setCurrency,
  errors,
  isSubmitting,
  setValues,
  validateForm,
  touched,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [inputData, setInputData] = useState({
    currency,
    inputAsset,
  });
  const currencies = useContext(CurrenciesContext);
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const isAuthorized = user.fetching ? false : !!user.data;
  const isNotVerified =
    !isNil(client.data) && client?.data?.status !== 'VERIFIED';
  const isShowLimitRate =
    typeof pathOr('', ['limit', 'min'], data) === 'number' &&
    typeof pathOr('', ['limit', 'max'], data) === 'number';
  const isTestingRequired = getClientTestingNeeded(client);

  const calculationInfo = useAxiosState(
    getCryptoCalculation(
      inputData.inputAsset ? fromLocaleNumber(inputData.inputAsset) : '0',
      inputData.currency
    ),
    [inputData.inputAsset, inputData.currency]
  );

  const { error } = calculationInfo;
  const isOperationDisabled = !isNil(data) && !data?.operationStatus?.enabled;

  const network = currencyValue =>
    getNetworkByCurrency(currencies?.data?.cryptoCurrencies, currencyValue);

  const selectedCurrency = useMemo(
    () =>
      currencies?.data?.cryptoCurrencies.find(cur => cur.currency === currency),
    [currencies, currency]
  );
  const currencyBalance = useAxiosState(
    getCurrencyBalance(selectedCurrency?.currency),
    [selectedCurrency?.currency]
  );

  useEffect(() => {
    if (currencyBalance?.data) {
      setValues({
        calculation: {
          inputAsset: currencyBalance?.data?.amount.toString(),
        },
        currency: selectedCurrency?.currency,
      });
    }
  }, [currencyBalance, selectedCurrency]);

  useEffect(() => {
    const idx = setTimeout(() => {
      setInputData({ currency, inputAsset });
    }, DEBOUNCE_TIMER);
    return () => {
      clearTimeout(idx);
    };
  }, [currency, inputAsset]);

  useEffect(() => {
    if (!isEmpty(calculationInfo.data) && !isEmpty(currencyBalance?.data)) {
      setData(calculationInfo.data);
      if (calculationInfo?.data?.limit) {
        localStorage.setItem(
          'limit',
          JSON.stringify(calculationInfo.data.limit)
        );
        validateForm();
      }
    }
  }, [calculationInfo, currencyBalance]);

  return (
    <Container>
      <ExchangerWrapper>
        <ExchangerWrapperAdditional>
          <HeaderCalculationWrapper>
            <WbWalletBtn
              onClick={() => history.push(`/profile?tab=${WALLETS}`)}
              type="button"
            >
              {t('calculationView.wbWallet')}
            </WbWalletBtn>
          </HeaderCalculationWrapper>
          <Form>
            <OutputMainContainer>
              <OutputNetworkContainer>
                <DropdownInput
                  {...props}
                  value={inputAsset}
                  label={t('calculationView.outputWalletTitle', {
                    value: network(currency),
                  })}
                  name="calculation.inputAsset"
                  currencyType={currency}
                  selectName="currency"
                  isShowLimitRate={isShowLimitRate}
                  setCurrencyPair={setCurrency}
                  selectOptions={currencies?.data?.cryptoCurrencies.map(it => ({
                    value: it.currency,
                    label: getCurrencyLabel(it.currency),
                  }))}
                  isLimitErrorBottom
                  ErrorComponent={
                    isAuthorized &&
                    isShowLimitRate &&
                    (() => (
                      <LimitRateError
                        information={data}
                        errorLimit={errors.calculation}
                      />
                    ))
                  }
                />
              </OutputNetworkContainer>
              <OutputAddressContainer>
                <LabelInput>
                  {t('calculationView.outputAddressTitle')}
                </LabelInput>
                <InputWrapper>
                  <Input
                    placeholder={t('calculationView.outputAddressPlaceholder')}
                    value={externalCryptoAddress || ''}
                    name="externalCryptoAddress"
                    isExtType
                    isError={
                      errors.externalCryptoAddress &&
                      touched.externalCryptoAddress
                    }
                    {...props}
                  />
                  {touched.externalCryptoAddress &&
                    errors.externalCryptoAddress && (
                      <ErrorMessage>
                        {errors.externalCryptoAddress}
                      </ErrorMessage>
                    )}
                </InputWrapper>
                <OutputList>
                  <OutputItem>
                    {t('calculationView.outputNetworkText')}
                    <OutputItemInfo>{network(currency)}</OutputItemInfo>
                  </OutputItem>
                  <OutputItem>
                    {t('calculationView.outputAddressText', {
                      value: getCurrencyValue(currency),
                    })}
                  </OutputItem>
                </OutputList>
              </OutputAddressContainer>
            </OutputMainContainer>
            <AdditionalChargeRow
              t={t}
              chargeData={{
                serviceFee: {
                  value: selectedCurrency?.internalWithdrawFee,
                  currency: getCurrencyValue(selectedCurrency?.currency),
                },
              }}
            />
            <SubmitButton
              information={calculationInfo}
              calcError={errors.calculation || errors}
              isSubmitting={isSubmitting}
              isTestingRequired={isTestingRequired}
              commission={selectedCurrency?.internalWithdrawFee}
              buttonName={t('converterCard.output')}
            />
          </Form>
        </ExchangerWrapperAdditional>
        {isNotVerified && (
          <SystemInfoError>
            <div>
              {t('calculationView.errors.validationUserVerification', {
                operation: 'вывода',
              })}
            </div>
          </SystemInfoError>
        )}
        {errors?.orderCreation?.message && (
          <SystemInfoError>
            <div>{errors.orderCreation.message}</div>
          </SystemInfoError>
        )}
        {isAuthorized && error?.status && (
          <SystemInfoError>
            <div>{requestErrors[error.status]}</div>
          </SystemInfoError>
        )}
        {isOperationDisabled && (
          <SystemInfoError>
            <div>
              {t(`workTimeErrors.${data?.operationStatus?.status}`, {
                operation: 'вывода',
              })}
            </div>
          </SystemInfoError>
        )}
        {isShowLimitRate && (
          <LimitRate t={t} information={data} errorLimit={errors.calculation} />
        )}
      </ExchangerWrapper>
    </Container>
  );
};

export default OutputCalculator;
