import React, { useContext } from 'react';
import CurrenciesContext from 'utils/contexts/Currencies';
import { getNetworkByCurrency } from 'utils/crypto';
import getLabelByCurrency from 'utils/form/getLabelByCurrency';

import {
  CurrencyIcon,
  OutputOperation,
  OutputText,
  OutputValue,
  Instruction,
  InstructionList,
  InstructionItem,
  InstructionItemInfo,
} from './styled-ui';

const OutputOperationStatus = ({ t, data }) => {
  const currencies = useContext(CurrenciesContext);
  const network = currencyValue =>
    getNetworkByCurrency(currencies?.data?.cryptoCurrencies, currencyValue);

  const { internalWithdrawFee } = currencies?.data?.cryptoCurrencies?.find(
    ({ currency }) => currency === data?.cryptoTransaction?.currency
  );

  return (
    <>
      <OutputOperation>
        <OutputText isCurrency>
          {t('pending.outputCurrency')}
          <OutputValue>
            <CurrencyIcon>
              {getLabelByCurrency(data?.cryptoTransaction.currency)}
            </CurrencyIcon>
          </OutputValue>
        </OutputText>
        <OutputText>
          {t('pending.outputNetwork', {
            value: network(data?.cryptoTransaction?.currency),
          })}
        </OutputText>
        <OutputText>
          {t('pending.outputSum', {
            value: data?.exchangeOperation?.inputAsset,
          })}
        </OutputText>
        <OutputText>
          {t('pending.outputFee', {
            value: internalWithdrawFee,
          })}
        </OutputText>
        <OutputText>
          {t('pending.outputAddress', {
            value: data?.cryptoTransaction?.toAddress,
          })}
        </OutputText>
      </OutputOperation>
      <Instruction>
        <InstructionList>
          <InstructionItem>{t('pending.outputInstruction1')}</InstructionItem>
          <InstructionItem>
            {t('pending.outputInstruction2')}
            <InstructionItemInfo>
              {network(data?.cryptoTransaction?.currency)}
            </InstructionItemInfo>
          </InstructionItem>
          <InstructionItem>{t('pending.outputInstruction3')}</InstructionItem>
          <InstructionItem>
            {t('calculationView.outputCommissionInfo')}
          </InstructionItem>
        </InstructionList>
      </Instruction>
    </>
  );
};

export default OutputOperationStatus;
