import styled from 'styled-components';
import device from 'device';

export const OutputTitle = styled.div`
  padding-left: 30px;
  padding-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${props => props.theme.blackText};
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;

export const OutputOperation = styled.div`
  border-radius: 8px;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${props => props.theme.grayBg};
  margin-bottom: 30px;
`;

export const OutputText = styled.div`
  display: flex;
  align-items: ${props => (props.isCurrency ? 'center' : 'flex-start')};
  font-size: 18px;
  line-height: 22.5px;
  color: ${props => props.theme.blackText};
  margin-bottom: 10px;
`;

export const OutputHashText = styled.div`
  font-size: 18px;
  line-height: 22.5px;
  color: ${props => props.theme.blackText};
  margin-right: 5px;
`;

export const OutputLabelValue = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const OutputValue = styled.div`
  display: flex;
  align-items: center;
`;

export const CurrencyIcon = styled.div`
  svg {
    width: 36px;
    height: 33px;
  }
  margin-right: 5px;
  margin-left: 5px;
  @media ${device.tablet} {
    svg {
      width: 25px;
      height: 30px;
    }
  }
`;

export const Instruction = styled.div`
  font-family: Inter;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.blackText};
  padding-left: 20px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const InstructionList = styled.ul`
  margin-top: 17px;
`;

export const InstructionItem = styled.li`
  margin-bottom: 10px;
`;

export const InstructionItemInfo = styled.span`
  margin-left: 5px;
  color: #ff0000;
`;
