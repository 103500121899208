import React, { useContext, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeContext, ThemeProvider } from 'styled-components';
import i18n from 'i18n';
import { HOME, VERIFICATIONS } from 'constants/routings';
import SessionTimer from 'ui-kit/SessionTimer';
import { GlobalModal as Modal } from 'ui-kit/Modal/Modal';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import NotificationContext from 'utils/contexts/Notification';
import CurrenciesContext from 'utils/contexts/Currencies';
import GeoContext from 'utils/contexts/Geo';
import ModalContext from 'utils/contexts/Modal';
import OrderDetailsContext from 'utils/contexts/OrderDetails';
import {
  useNotifications,
  useConditionAxiosStateWithRefetch,
  useAxiosState,
  useAxiosStateWithRefetch,
} from 'utils/hooks/axiosHook';
import useModal from 'utils/hooks/useModal';
import { getUserRole, isUserAnonymous } from 'utils/services/selectors/user';
import { removeToken } from 'utils/axiosUtil';
// import { bootIntercom, loginIntercom } from 'utils/intercom';
import {
  getCurrencies,
  getUser,
  getUserStatus,
} from 'utils/services/request/app';
import { geoInfo } from 'utils/services/request/auth';
import {
  getPathRoute,
  replaceLocalePath,
  setSumsubLang,
  setUserLocale,
} from 'utils/location';
import { getOrdersDetails } from 'utils/services/request/operations';
import USER_ROLES from 'utils/constants/userRoles';
import FullscreenLoader from 'components/FullscreenLoader';
import './index.css';
import PrivateRoute from './routes/private';
import PublicRoute from './routes/public';
import LocaleRouter from './routes/localeRouter';
import HistoryListener from './historyListener';
import blueTheme from './themes/blue';
import { bootAnalytics } from './analytics';

const history = createBrowserHistory();

const SetFavicon = () => {
  const themeContext = useContext(ThemeContext);
  const favicon = document.getElementById('favicon');
  favicon.href = themeContext.favicon;
};

const Routers = () => {
  SetFavicon();
  const user = useContext(UserContext);
  const isAnonymous = isUserAnonymous(user);
  if (!user.loaded || user.fetching) {
    return <FullscreenLoader />;
  }

  return (
    <>
      <Router history={history}>
        <HistoryListener>
          <Switch>
            <LocaleRouter
              path={getPathRoute(VERIFICATIONS)}
              Component={PrivateRoute}
            />
            <LocaleRouter path={HOME} Component={PublicRoute} />
          </Switch>
        </HistoryListener>
        {!isAnonymous && <SessionTimer />}
      </Router>
    </>
  );
};

const App = () => {
  // bootIntercom();
  bootAnalytics();

  const { notificationState, dispatchNotification } = useNotifications();
  const { modal, openModal, closeModal } = useModal();

  const user = useConditionAxiosStateWithRefetch(getUser);
  const isDefaultUser = getUserRole(user, USER_ROLES.USER);
  const condition = user.loaded && user.data?.email && isDefaultUser;
  const client = useConditionAxiosStateWithRefetch(
    condition ? getUserStatus : {}
  );
  const geo = useAxiosState(geoInfo);
  const currencies = useAxiosState(getCurrencies);
  const orderDetails = useAxiosStateWithRefetch(
    condition ? getOrdersDetails : {}
  );

  useEffect(() => {
    if (user.loaded && !user.fetching && user.error) {
      removeToken();
    }
    // if (user.loaded && user.data) {
    //   loginIntercom(user.data.email);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (geo.loaded && geo.data?.country) {
      setUserLocale(geo.data.country);
      setSumsubLang(geo.data.country);
      replaceLocalePath();
    }
  }, [geo]);

  return (
    <ThemeProvider theme={blueTheme}>
      <UserContext.Provider value={user}>
        <ClientContext.Provider value={client}>
          <GeoContext.Provider value={geo}>
            <CurrenciesContext.Provider value={currencies}>
              <OrderDetailsContext.Provider value={orderDetails}>
                <ModalContext.Provider value={{ modal, openModal, closeModal }}>
                  <NotificationContext.Provider
                    value={{ notificationState, dispatchNotification }}
                  >
                    <I18nextProvider i18n={i18n}>
                      <Routers />
                      <Modal />
                    </I18nextProvider>
                  </NotificationContext.Provider>
                </ModalContext.Provider>
              </OrderDetailsContext.Provider>
            </CurrenciesContext.Provider>
          </GeoContext.Provider>
        </ClientContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default App;
