import { prop, sortBy } from 'ramda';
import { cryptoCurrencies, fiatCurrencies } from './constant';

export const isCrypto = value => {
  return cryptoCurrencies.includes(value);
};

export const getFiatCurrencyFromPair = pair => {
  return fiatCurrencies.includes(pair.fromCurrency)
    ? pair.fromCurrency
    : pair.toCurrency;
};

export const getCryptoCurrencyFromPair = pair => {
  return cryptoCurrencies.includes(pair.fromCurrency)
    ? pair.fromCurrency
    : pair.toCurrency;
};

export const getPairLabel = currencyPair =>
  `${currencyPair.fromCurrency}/${currencyPair.toCurrency}`;

export const getSortedPairs = unsortedPairs =>
  sortBy(
    prop('label'),
    unsortedPairs?.map(({ currencyPair, enabled }) => ({
      currencyPair,
      enabled,
      label: getPairLabel(currencyPair),
    })) || {}
  );

export const getSeparatedPairs = allPairs => {
  const buy = [];
  const sell = [];

  allPairs.map(pair => {
    const {
      currencyPair: { fromCurrency, toCurrency },
    } = pair;
    const reversedPair = allPairs.find(
      ({ currencyPair }) =>
        currencyPair.fromCurrency === toCurrency &&
        currencyPair.toCurrency === fromCurrency
    );

    if (isCrypto(fromCurrency)) {
      buy.push(pair);
      sell.push(reversedPair);
    }
    return null;
  });
  return { buy, sell };
};

export const getNetworkByCurrency = (currencies, value) => {
  const { network } = currencies.find(({ currency }) => currency === value);

  return network;
};

export const getCurrencyValue = currency => {
  switch (currency) {
    case 'USDT_TRC':
      return 'USDT';
    default:
      return currency;
  }
};
