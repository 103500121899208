import { ReactComponent as CloseImg } from 'assets/images/icons/close.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/cancel.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/icons/check-blue.svg';
import { LaterButton } from 'components/Verification/styled-ui';
import styled from 'styled-components';
import device from 'device';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'unset')};
  left: 0;
  top: 0;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  z-index: 99999;
  position: ${({ noRoute }) => noRoute && 'fixed'};
  background: ${({ noRoute }) => noRoute && 'rgba(247, 247, 248, 0.9)'};
  height: ${({ noRoute }) => noRoute && '100vh'};
  overflow-y: auto;
  min-height: inherit;
  @media (max-width: 845px) {
    padding: 134px 50px 50px 50px;
  }
  @media (max-width: 509px) {
    padding: 100px 15px;
  }
`;

export const Window = styled.div`
  background: ${props => (props.noRoute ? 'none' : props.theme.blueBg)};
  background-size: cover;
  min-height: ${props => (props.noRoute ? 'inherit' : '100vh')};
  @media (max-width: 845px) {
    padding: 0;
  }
`;

export const ModalCard = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  border-radius: 10px;
  padding: ${({ padding }) => padding || '50px 215px'};
  max-width: ${({ maxWidth }) => maxWidth || '1266px'};
  height: ${({ noRoute }) => noRoute && '100%'};
  width: 100%;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  box-sizing: border-box;

  @media ${device.laptop} {
    padding: 35px;
  }

  @media ${device.mobileL} {
    padding: 30px;
  }

  @media ${device.mobileM} {
    padding: 30px 15px;
  }
`;

export const SmallModalCard = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  border-radius: 10px;
  width: ${({ width }) => (width ? `${width}` : '557px')};
  padding: 50px;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  box-sizing: border-box;
  @media ${device.mobile920} {
    padding: 25px;
    width: inherit;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : '557px')};
  }
`;

export const Close = styled(CloseImg)`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '20px')};
  right: ${({ right }) => (right ? `${right}px` : '20px')};
  border: none;
  background: transparent;
  width: 18px;
  height: 18px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media ${device.laptop} {
    top: ${({ top }) => (top ? `${top}px` : '15px')};
    right: ${({ right }) => (right ? `${right}px` : '15px')};
  }

  @media ${device.mobileL} {
    top: ${({ top }) => (top ? `${top}px` : '10px')};
    right: ${({ right }) => (right ? `${right}px` : '10px')};
  }
`;

export const CompletedIcon = styled(SuccessIcon)`
  width: 100%;
  height: 100px;
  margin: 20px 0;
`;

export const CrossErrorIcon = styled(ErrorIcon)`
  width: 100%;
  height: 100px;
  margin: 20px 0;
`;

export const SmallClose = styled(Close)`
  top: 25px;
  right: 25px;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.5;
`;

export const Header = styled.div`
  &.header {
    color: ${props => props.theme.blackText};
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    @media ${device.laptop} {
      font-size: 24px;
      line-height: 30px;
    }
    padding-bottom: ${props => (props.padding ? `${props.padding}px` : '30px')};
    text-align: ${props => props.textalign || 'inherit'};

    @media ${device.mobile920} {
      padding-bottom: 25px;
    }
  }
`;

export const Info = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 23px;
  }
  padding-bottom: ${props => (props.padding ? `${props.padding}px` : '20px')};
  text-align: ${props => props.textalign || 'inherit'};

  span {
    color: ${props => props.theme.linkColor};
  }
`;

export const FormGrid = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
`;

export const WrapperButton = styled(FormGrid)`
  padding-top: 20px;
`;

export const WrapperButtonClear = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
`;

export const DialogAcceptButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background-color: ${({ mainBtnColor }) =>
    mainBtnColor ? '#0069ff' : '#ff0200'};
  width: 100%;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  grid-column: ${props => (props.notification ? '1/-1' : 'initial')};
  cursor: pointer;

  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }
`;

export const DialogCancelButton = styled(LaterButton)`
  height: 56px;
  font-size: 1rem;
  color: #0f2f56;
  font-weight: 500;
  grid-column: ${props => (props.notification ? '1/-1' : 'unset')};
`;
