import { localeNumberFromCustomNumber } from 'utils/number';
import { getCurrencyValue } from 'utils/crypto';

const validationFieldsValue = (clientStatus, t) => values => {
  let errors = {};
  const limit = JSON.parse(localStorage.getItem('limit'));
  if (!values.calculation.inputAsset) {
    errors = {
      ...errors,
      calculation: {
        inputAsset: t('calculationView.errors.validationErrorInputAsserEmpty'),
      },
    };
  }

  if (values.calculation.inputAsset) {
    const value = localeNumberFromCustomNumber(
      values.calculation.inputAsset,
      values.currency
    )
      .replace(/,/g, '.')
      .replace(/\s/g, '');
    if (limit.min > limit.max || limit.max === 0 || limit.max === null) {
      if (clientStatus === 'VERIFIED') {
        errors = {
          ...errors,
          calculation: {
            inputAsset: t(
              'calculationView.errors.validationErrorLimitExceeded'
            ),
          },
        };
      }
    } else if (value < limit.min) {
      errors = {
        ...errors,
        calculation: {
          inputAsset: t(
            'calculationView.errors.validationErrorOutDownOfLimit',
            {
              value: limit.min,
              currency: getCurrencyValue(values.currency),
            }
          ),
        },
      };
    } else if (value > limit.max) {
      errors = {
        ...errors,
        calculation: {
          inputAsset: t('calculationView.errors.validationErrorOutUpOfLimit'),
        },
      };
    }
  }
  if (!values.externalCryptoAddress) {
    errors = {
      ...errors,
      externalCryptoAddress: t(
        'calculationView.errors.validationErrorWalletEmpty'
      ),
    };
  }
  return errors;
};

export default validationFieldsValue;
