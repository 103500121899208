/* eslint-disable no-nested-ternary */
import React from 'react';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getFaqs } from 'utils/services/request/faq';
import FaqAccordion from 'ui-kit/FaqAccordion';
import Spinner from 'ui-kit/Spinner';
import ErrorModal from 'components/ErrorModal';
import {
  AccordionWrapper,
  FaqWrapper,
  LoadWrapper,
  QuestionCategory,
} from './styled-ui';

const query = qs.stringify(
  {
    locale: ['ru'],
    sort: ['order:asc'],
    populate: {
      faqs: {
        sort: ['Order:asc'],
      },
    },
  },
  {
    encodeValuesOnly: true,
  }
);

const Faq = () => {
  const faqsCategories = useAxiosState(getFaqs(query));

  return (
    <>
      <Helmet>
        <title>
          Вопросы-ответы по покупке криптовалюты в Беларуси, России, Украине |
          Whitebird
        </title>
        <meta
          name="description"
          content="Сервис Whitebird позволяет быстро купить или продать Bitcoin (BTC) за USD, RUB, BYN по оптимальному курсу на момент проведения операции. Покупайте Биткоин за рубли с банковской карты онлайн!"
        />
        <meta
          property="og:title"
          content="Вопросы-ответы по покупке криптовалюты в Беларуси, России, Украине | Whitebird"
        />
        <meta
          property="og:description"
          content="Сервис Whitebird позволяет быстро купить или продать Bitcoin (BTC) за USD, RUB, BYN по оптимальному курсу на момент проведения операции. Покупайте Биткоин за рубли с банковской карты онлайн!"
        />
      </Helmet>
      <FaqWrapper>
        {!faqsCategories.data && !faqsCategories.error ? (
          <LoadWrapper>
            <Spinner color="#FFFFFF" />
          </LoadWrapper>
        ) : (
          <>
            {faqsCategories.data ? (
              faqsCategories.data.data.map(category => (
                <div key={category.id}>
                  <QuestionCategory>
                    {category.attributes.Caption}
                  </QuestionCategory>
                  <AccordionWrapper allowMultipleExpanded allowZeroExpanded>
                    {category.attributes.faqs.data.map(faq => (
                      <FaqAccordion
                        key={faq.id}
                        title={faq.attributes.Caption}
                        description={faq?.attributes?.Text}
                      />
                    ))}
                  </AccordionWrapper>
                </div>
              ))
            ) : (
              <ErrorModal isReloadButton />
            )}
          </>
        )}
      </FaqWrapper>
    </>
  );
};

export default Faq;
