import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Formik } from 'formik';
import { SimpleModal } from 'ui-kit/Modal/Modal';
import UserContext from 'utils/contexts/User';
import ModalContext from 'utils/contexts/Modal';
import GeoContext from 'utils/contexts/Geo';
import {
  useAxiosSubmitingEffect,
  useAxiosSubmitingEffectWithHeaders,
} from 'utils/hooks/axiosHook';
import { changePhone, changePhoneForRus } from 'utils/services/request/auth';
import { setGender as setGenderRequest } from 'utils/services/request/app';
import { COUNTRY_CODES } from 'utils/countryCodes';
import { getCodeByCountry } from 'components/Auth/helpers';
import validationPhoneScheme from 'components/Auth/validation';
import ApproveModal from 'components/Auth/UserProfile/ApproveModal';
import ConfirmChangePhone from 'components/Auth/UserProfile/ApproveModal/ChangePhone';
import UserDetailsModal from './UserDetailsModal';

const ConfirmationForm = ({
  t,
  isShowUserDetailsModal,
  setIsShowUserDetailsModal,
  setIsShowConsentForm,
  isUserNotHavePhone,
  isUserNotHaveGender,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const user = useContext(UserContext);
  const geo = useContext(GeoContext);
  const { openModal } = useContext(ModalContext);
  const [confirmModalStep, setConfirmModalStep] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [gender, setGender] = useState('');
  const [tokenGoogle, setTokenGoogle] = useState('');

  const handleReCaptchaVerify = useCallback(
    tokenS => {
      if (tokenGoogle) return;
      setTokenGoogle(tokenS);
    },
    [setTokenGoogle, tokenGoogle]
  );

  const stateSubmitingUserDataForm = useAxiosSubmitingEffectWithHeaders(
    countryCode === COUNTRY_CODES.BY ? changePhone : changePhoneForRus
  );
  const fetchPhone = async (values, actions) => {
    const result = await executeRecaptcha('signUp');
    stateSubmitingUserDataForm.setFormAndSubmit({
      headers: {
        recaptcha: result,
      },
      values: { countryCode: values.countryCode, phone: values.phone },
      actions,
    });
  };

  const stateSubmitingGender = useAxiosSubmitingEffect(setGenderRequest);
  const fetchGender = actions => {
    stateSubmitingGender.setFormAndSubmit({
      values: { gender },
      actions,
    });
  };

  const onSubmitHandler = (values, actions) => {
    if (!isUserNotHavePhone && isUserNotHaveGender) {
      fetchGender(actions);
    } else {
      fetchPhone(values, actions);
    }
  };

  const onSuccessCallback = props => {
    // eslint-disable-next-line no-param-reassign
    user.data.phone = props.phone;
    setIsShowUserDetailsModal(false);
    if (isUserNotHavePhone && !isUserNotHaveGender) {
      setIsShowConsentForm(true);
    }
    if (isUserNotHaveGender) {
      fetchGender(undefined);
    }
  };

  useEffect(() => {
    if (stateSubmitingUserDataForm.loaded) {
      const { actions } = stateSubmitingUserDataForm.form;
      if (stateSubmitingUserDataForm.error) {
        const { status, message } = stateSubmitingUserDataForm.error;
        if (status === 'INVALID_CODE') {
          if (message === 'Secret must not be empty') {
            setConfirmModalStep(1);
          }
          if (message === 'Code has been sent. Try again later') {
            actions.setErrors({
              phone: t('profile.tooManyRequest'),
            });
          }
        } else {
          setIsShowUserDetailsModal(false);
          openModal({
            isError: true,
            message: t('errorModal.title'),
          });
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (
        stateSubmitingUserDataForm?.data?.status === 'OK' &&
        countryCode === COUNTRY_CODES.BY
      ) {
        setConfirmModalStep(2);
        actions.setSubmitting(false);
      } else if (
        stateSubmitingUserDataForm?.data &&
        countryCode !== COUNTRY_CODES.BY
      ) {
        actions.setSubmitting(false);
        onSuccessCallback(stateSubmitingUserDataForm.data);
      }
    }
  }, [stateSubmitingUserDataForm]);

  useEffect(() => {
    if (stateSubmitingGender.loaded) {
      const { actions, values } = stateSubmitingGender.form;
      if (
        stateSubmitingGender.error &&
        // Custom request hook returns 'Not found' if response is empty
        stateSubmitingGender.error !== 'Not found'
      ) {
        actions?.setSubmitting(false);
        setIsShowUserDetailsModal(false);
        openModal({
          isError: true,
          message: t('errorModal.title'),
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        user.data.gender = values.gender;
        actions?.setSubmitting(false);
        setIsShowUserDetailsModal(false);
        setIsShowConsentForm(true);
      }
    }
  }, [stateSubmitingGender]);

  const onCloseModal = () => {
    setConfirmModalStep(null);
  };

  return (
    <Formik
      initialValues={{
        countryCode: getCodeByCountry(geo?.data?.country),
        phone: '',
        mfaSecret: '',
        gender: '',
      }}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validationSchema={isUserNotHavePhone && validationPhoneScheme(t)}
    >
      {formik => (
        <Form>
          {isShowUserDetailsModal && (
            <SimpleModal
              Component={UserDetailsModal}
              width="auto"
              maxWidth="500px"
              onClose={() => setIsShowUserDetailsModal(false)}
              t={t}
              user={user}
              setCountryCode={setCountryCode}
              setGender={setGender}
              isUserNotHavePhone={isUserNotHavePhone}
              isUserNotHaveGender={isUserNotHaveGender}
              {...formik}
            />
          )}
          {confirmModalStep && (
            <ApproveModal
              initialValues={formik.values}
              errors={formik.errors}
              isSubmitting={formik.isSubmitting}
              onSubmitHandler={formik.submitForm}
              t={t}
              Component={ConfirmChangePhone}
              onClose={onCloseModal}
              onSuccessCallback={onSuccessCallback}
              confirmModalStep={confirmModalStep}
              setConfirmModalStep={setConfirmModalStep}
            />
          )}
          {process.env?.REACT_APP_RECAPTCHA_KEY && (
            <GoogleReCaptcha action="signUp" onVerify={handleReCaptchaVerify} />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmationForm;
