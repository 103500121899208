import styled, { keyframes } from 'styled-components';
import { ReactComponent as SignIn } from 'assets/images/icons/signin-light.svg';
import { ReactComponent as BYN } from 'assets/images/icons/BYN.svg';
import { ReactComponent as USD } from 'assets/images/icons/USD.svg';
import { ReactComponent as EUR } from 'assets/images/icons/EUR.svg';
import { ReactComponent as RUB } from 'assets/images/icons/RUB.svg';
import { ReactComponent as BTC } from 'assets/images/icons/BTC.svg';
import { ReactComponent as ETH } from 'assets/images/icons/ETH.svg';
import { ReactComponent as USDT } from 'assets/images/icons/USDT.svg';
import { ReactComponent as TRON } from 'assets/images/icons/TRON.svg';
import { ReactComponent as TON } from 'assets/images/icons/TON.svg';
import { ReactComponent as WBP } from 'assets/images/icons/WBP.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import device from 'device';

export const BYNIcon = styled(BYN)`
  margin-right: 10px;
`;
export const USDIcon = styled(USD)`
  margin-right: 10px;
`;
export const EURIcon = styled(EUR)`
  margin-right: 10px;
`;
export const RUBIcon = styled(RUB)`
  margin-right: 10px;
`;
export const BTCIcon = styled(BTC)`
  margin-right: 10px;
`;
export const ETHIcon = styled(ETH)`
  margin-right: 10px;
`;
export const USDTIcon = styled(USDT)`
  margin-right: 10px;
`;
export const TRONIcon = styled(TRON)`
  margin-right: 10px;
`;
export const USDTTRCIcon = styled(USDT)`
  margin-right: 10px;
`;
export const TONIcon = styled(TON)`
  margin-right: 10px;
`;
export const WBPIcon = styled(WBP)`
  margin-right: 10px;
`;

export const CryptoNetwork = styled.div`
  color: ${props => props.theme.grayText};
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  height: 22px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const LabelText = styled.span`
  font-size: 28px;
  color: ${props => props.theme.blackText};
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const ExchangeOperation = styled.div`
  grid-column: 1 / -1;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  display: grid;
  grid-template-areas:
    'a c d'
    'b c e'
    'f f f';
  grid-gap: 20px;
  border-radius: 8px;
  background-color: ${({ bgColor, isNext, theme }) =>
    bgColor && isNext ? bgColor : theme.grayBg};
  padding: 25px;
  @media ${device.tablet} {
    padding: 20px;
    grid-template-areas:
      'a a'
      'b b'
      'c c'
      'd d'
      'e e'
      'f f';
  }
  @media ${device.mobileL} {
    padding: 20px 10px 40px 10px;
  }
`;

export const HashTransaction = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: ${props => props.area};
  margin-top: 10px;
  & svg {
    width: 20px;
    height: 22px;
    cursor: pointer;
  }
  @media ${device.tablet} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const HashText = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  white-space: pre;
  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const HashContent = styled.div`
  display: flex;
  align-items: center;
`;

export const HashValue = styled.div`
  font-size: 16px;
  line-height: 19px;
  overflow-wrap: break-word;
  word-break: break-word;
  color: ${props => props.theme.blackText};
`;

export const Instruction = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const PriceAnimation = keyframes`
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
`;
export const Price = styled.div`
  display: flex;
  color: ${props => props.theme.blackText};
  font-size: 28px;
  line-height: 35px;
  font-weight: 500;
  animation-name: ${({ animationUpdate }) =>
    animationUpdate ? PriceAnimation : 'none'};
  animation-duration: 1.5s;
  @media ${device.tablet} {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const ExchangeRatePrice = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 14px;
  line-height: 17px;
  padding-top: 10px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 16px;
  }
  animation-name: ${({ animationUpdate }) =>
    animationUpdate ? PriceAnimation : 'none'};
  animation-duration: 1.5s;
`;

export const EripWrapper = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 16px;

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const BankAccountValue = styled.div`
  font-family: 'Inter';
  font-size: 14px;
  line-height: 17px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 16px;
  }
  padding: 0 10px;
`;

export const CryptoAddress = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 19px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const EripNumber = styled(CryptoAddress)``;

export const CryptoAddressWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${props => (props.isBlockHeader ? '1/-1' : 'auto')};
  width: ${props => (props.isAddressInfo ? '100%' : 'initial')};
  @media ${device.tablet} {
    grid-column: 1/-1;
    align-items: ${props => (props.area === 'c' ? 'center' : '')};
  }
  grid-area: ${props => props.area};
  justify-content: ${props => (props.area === 'c' ? 'center' : '')};
`;

export const ExchangeOperationNumber = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 46px;
  @media ${device.tablet} {
    font-weight: 500;
    font-size: 34px;
    line-height: 41px;
  }
  padding: 0 0 25px 0;
`;

export const ExchangeOperationDate = styled.div`
  display: inline-block;
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 17px;
    margin: 0 15px 15px 0;
  }
  letter-spacing: 0;
  margin-right: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 14px;
  line-height: 24px;
`;

export const BankAccountNumber = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  > span {
    max-width: 300px;
    word-break: break-word;
  }
`;

export const DownloadIcon = styled(SignIn)`
  transform: rotate(90deg);
`;

export const ArrowIcon = styled(Arrow)`
  @media ${device.tablet} {
    display: flex;
    transform: rotate(90deg);
  }
`;

export const Charges = styled.div`
  padding-top: 10px;
  > * {
    padding-bottom: 5px;
  }
`;

export const ExchangeWalletType = styled.div`
  color: ${props => props.theme.grayText};
  font-weight: 500;
  font-size: 13px;
  line-height: 15.73px;
  text-transform: uppercase;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 30px 25px 0;

  @media ${device.tablet} {
    margin: 20px 20px 0;
  }

  > label {
    @media ${device.laptop} {
      justify-content: center;
    }
  }

  @media ${device.mobileL} {
    margin: 20px 10px 0;
  }
`;

export const CheckboxText = styled.span`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  cursor: pointer;
  a {
    text-decoration: none;
  }

  @media ${device.laptop} {
    white-space: pre-line;
  }

  @media (max-width: 575px) {
    white-space: normal;
  }
`;

export const CheckBoxAddressText = styled(CheckboxText)`
  max-width: 380px;
  word-break: break-word;
  font-size: 14px;
`;
