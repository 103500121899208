import React, { useEffect, useCallback, useState } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { changePhone, changePhoneVerify } from 'utils/services/request/auth';
import {
  useAxiosSubmitingEffect,
  useAxiosSubmitingEffectWithHeaders,
} from 'utils/hooks/axiosHook';
import { ERROR_MESSAGES, ERROR_STATUSES } from 'utils/constants/errors';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import ConfirmationPhone from 'components/ConfirmationPhone';
import {
  ModalBodyWrapper,
  ModalLabel,
  ModalDesc,
  ButtonWrapper,
} from './styled-ui';

const validationScheme = t =>
  Yup.object().shape({
    mfaSecret: Yup.string().required(t('profile.requiredField')),
  });

const ChangePhoneForm = ({
  t,
  initialValues,
  hideModal,
  onSuccessCallback,
  confirmModalStep,
  setConfirmModalStep,
  setIsSkipErrors,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const stateSubmittingMfa = useAxiosSubmitingEffectWithHeaders(changePhone);
  const stateSubmittingPhoneVerify = useAxiosSubmitingEffect(changePhoneVerify);
  const [tokenGoogle, setTokenGoogle] = useState('');

  const handleReCaptchaVerify = useCallback(
    tokenS => {
      if (tokenGoogle) return;
      setTokenGoogle(tokenS);
    },
    [setTokenGoogle, tokenGoogle]
  );

  const onSubmitHandler = useCallback(
    async (values, actions) => {
      const result = await executeRecaptcha('signUp');
      stateSubmittingMfa.setFormAndSubmit({
        headers: {
          recaptcha: result,
        },
        values,
        actions,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  );

  const onSubmitVerifyHandler = useCallback(
    (values, actions) =>
      stateSubmittingPhoneVerify.setFormAndSubmit({
        values: { code: Object.values(values).join('') },
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (stateSubmittingMfa.loaded) {
      const { actions } = stateSubmittingMfa.form;
      if (stateSubmittingMfa.error) {
        if (stateSubmittingMfa.error.status === ERROR_STATUSES.INVALID_CODE) {
          if (
            stateSubmittingMfa.error.message === ERROR_MESSAGES.INVALID_CODE
          ) {
            actions.setErrors({
              mfaSecret: t('passwordForms.errors.invalidCode'),
            });
          }
          if (
            stateSubmittingMfa.error.message ===
            ERROR_MESSAGES.CODE_HAS_BEEN_SENT
          ) {
            actions.setErrors({
              mfaSecret: t('profile.tooManyRequest'),
            });
          }
        } else if (
          stateSubmittingMfa.error.status === ERROR_STATUSES.USER_EXISTS
        ) {
          actions.setErrors({
            mfaSecret: t('signUpForm.errors.userExists'),
          });
        } else if (
          typeof setIsSkipErrors === 'function' &&
          stateSubmittingMfa.error.status !== ERROR_STATUSES.INVALID_CODE &&
          stateSubmittingMfa.error.status !== ERROR_STATUSES.USER_EXISTS
        ) {
          setIsSkipErrors(true);
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmittingMfa.data.status === 'OK') {
        setConfirmModalStep(2);
        // hideModal();
        // onSuccessCallback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingMfa.loaded]);

  useEffect(() => {
    if (stateSubmittingPhoneVerify.loaded) {
      const { actions } = stateSubmittingPhoneVerify.form;
      if (stateSubmittingPhoneVerify.error) {
        if (
          stateSubmittingPhoneVerify.error.status === ERROR_STATUSES.BAD_REQUEST
        ) {
          actions.resetForm();
          document.getElementById('otc-1').focus();
          setTimeout(
            () =>
              actions.setErrors({
                code: t('passwordForms.errors.invalidCode'),
              }),
            0
          ); // timeout because focus reset error
        } else if (typeof setIsSkipErrors === 'function') {
          setIsSkipErrors(true);
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmittingPhoneVerify.data) {
        hideModal();
        onSuccessCallback(stateSubmittingPhoneVerify?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingPhoneVerify.loaded]);

  const getStepChangePhoneModal = step => {
    switch (step) {
      case 1:
        return (
          <ModalBodyWrapper>
            <ModalLabel>{t('profile.modalLabels.twoFa')}</ModalLabel>
            <ModalDesc lastChild>
              {t('profile.modalLabels.twoFaCodeInfo')}
            </ModalDesc>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmitHandler}
              validateOnChange={false}
              validationSchema={validationScheme(t)}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <Field
                    type="text"
                    name="mfaSecret"
                    component={Input}
                    placeholder="******"
                    label={t('profile.inputLabels.code')}
                  />
                  {errors.mfaSecret && (
                    <Error isSupportPage>{errors.mfaSecret}</Error>
                  )}
                  <ButtonWrapper>
                    <Button type="submit" disabled={isSubmitting}>
                      {t('profile.modalLabels.approveButton')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </ModalBodyWrapper>
        );
      case 2:
        return (
          <ConfirmationPhone
            t={t}
            initialValues={initialValues}
            handleSubmit={onSubmitVerifyHandler}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {getStepChangePhoneModal(confirmModalStep)}
      {process.env?.REACT_APP_RECAPTCHA_KEY && (
        <GoogleReCaptcha action="signUp" onVerify={handleReCaptchaVerify} />
      )}
    </>
  );
};

export default ChangePhoneForm;
