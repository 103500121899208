import React from 'react';
import styled from 'styled-components';
import { CRYPTO_CURRENCIES, FIAT_CURRENCIES } from 'utils/constant';
import { getCurrencyValue } from 'utils/crypto';
import { ReactComponent as BTCIcon } from 'assets/images/icons/BTC.svg';
import { ReactComponent as ETHIcon } from 'assets/images/icons/ETH.svg';
import { ReactComponent as USDTIcon } from 'assets/images/icons/USDT.svg';
import { ReactComponent as TRONIcon } from 'assets/images/icons/TRON.svg';
import { ReactComponent as TONIcon } from 'assets/images/icons/TON.svg';
import { ReactComponent as WBPIcon } from 'assets/images/icons/WBP.svg';
import { ReactComponent as USDIcon } from 'assets/images/icons/USD.svg';
import { ReactComponent as EURIcon } from 'assets/images/icons/EUR.svg';
import { ReactComponent as BYNIcon } from 'assets/images/icons/BYN.svg';
import { ReactComponent as RUBIcon } from 'assets/images/icons/RUB.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Currency = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.01em;
  margin-left: 6px;
  color: ${props => props.theme.fullBlackText};
`;

const getLabelByCurrency = currency => {
  const renderCurrencies = () => {
    switch (currency) {
      case CRYPTO_CURRENCIES.BTC:
        return (
          <Wrapper>
            <BTCIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.ETH:
        return (
          <Wrapper>
            <ETHIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.USDT:
        return (
          <Wrapper>
            <USDTIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.TRX:
        return (
          <Wrapper>
            <TRONIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.USDT_TRC:
        return (
          <Wrapper>
            <USDTIcon />
            <Currency>{getCurrencyValue(currency)}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.TON:
        return (
          <Wrapper>
            <TONIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case CRYPTO_CURRENCIES.WBP:
        return (
          <Wrapper>
            <WBPIcon />
            <Currency>{getCurrencyValue(currency)}</Currency>
          </Wrapper>
        );
      case FIAT_CURRENCIES.USD:
        return (
          <Wrapper>
            <USDIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case FIAT_CURRENCIES.EUR:
        return (
          <Wrapper>
            <EURIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      case FIAT_CURRENCIES.BYN:
        return (
          <Wrapper>
            <BYNIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );

      case FIAT_CURRENCIES.RUB:
        return (
          <Wrapper>
            <RUBIcon />
            <Currency>{currency}</Currency>
          </Wrapper>
        );
      default:
        return null;
    }
  };

  return renderCurrencies();
};

export default getLabelByCurrency;
