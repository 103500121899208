import React, { useContext } from 'react';
import CurrenciesContext from 'utils/contexts/Currencies';
import { getNetworkByCurrency, getCurrencyValue } from 'utils/crypto';
import getLabelByCurrency from 'utils/form/getLabelByCurrency';
import PendingInstruction from 'components/PendingInstruction';

import {
  Circle,
  Network,
  ReplenishmentInfo,
  ReplenishmentOperation,
  ReplenishmentSubTitle,
  ReplenishmentTitle,
  ReplenishmentValue,
} from './styled-ui';

const ReplenishmentOperationStatus = ({
  data,
  walletQrData,
  additionalSubmitTimeout,
  setAdditionalSubmitTimeout,
  t,
}) => {
  const currencies = useContext(CurrenciesContext);
  const network = getNetworkByCurrency(
    currencies?.data?.cryptoCurrencies,
    data?.cryptoTransaction?.currency
  );

  return (
    <>
      <ReplenishmentOperation>
        <ReplenishmentTitle>{t('pending.replenishmentSum')}</ReplenishmentTitle>
        <ReplenishmentValue>
          <div>{data?.exchangeOperation?.inputAsset}</div>
          {getLabelByCurrency(data?.cryptoTransaction?.currency)}
        </ReplenishmentValue>
        <ReplenishmentSubTitle>
          {network && (
            <ReplenishmentInfo>
              <div>
                <Circle />
                <div>{t('pending.replenishmentInfoNetwork')}</div>
              </div>
              <Network>{network}</Network>
            </ReplenishmentInfo>
          )}
          <ReplenishmentInfo>
            <div>
              <Circle />
              <div>
                {t('pending.replenishmentInfoCurrency', {
                  currency: getCurrencyValue(data?.cryptoTransaction?.currency),
                })}
              </div>
            </div>
          </ReplenishmentInfo>
        </ReplenishmentSubTitle>
      </ReplenishmentOperation>
      <PendingInstruction
        amount={data?.inputAsset}
        address={data?.cryptoTransaction?.toAddress}
        walletQrData={walletQrData}
        t={t}
        instruction={t('pending.replenishmentInstructions')}
        additionalSubmitTimeout={additionalSubmitTimeout}
        setAdditionalSubmitTimeout={setAdditionalSubmitTimeout}
      />
    </>
  );
};

export default ReplenishmentOperationStatus;
