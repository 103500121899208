import React, { useState, useEffect, Fragment } from 'react';
import { isNil, pipe, last, pathOr, split } from 'ramda';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import { ReactComponent as IconReport } from 'assets/images/icons/Report.svg';
import Pagination from 'components/Auth/UserProfile/Pagination';
import { getCurrencyValue, isCrypto } from 'utils/crypto';
import { getUserLanguage } from 'utils/location';
import { convertCardNumber } from 'utils/number';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getOrdersHistoryByUser } from 'utils/services/request/operations';
import usePersistedState from 'utils/hooks/usePersistedState';
import {
  CRYPTO_CURRENCIES,
  EXCHANGE_TYPES,
  EXT_SOURCE,
  FIAT_CURRENCIES,
  INT_SOURCE,
  USDT_NETWORK_TYPES,
} from 'utils/constant';
import { fileDownload } from 'utils/fileDownload';
import { getClientOperations } from 'utils/services/request/card';
import { SimpleModal } from 'ui-kit/Modal/Modal';
import OperationReportModal from './OperationsReportModal';
import {
  StyledTable,
  TableHead,
  TableBody,
  LabelWrapper,
  LabelText,
  BTCIcon,
  ETHIcon,
  USDTIcon,
  RUBIcon,
  BYNIcon,
  USDIcon,
  EURIcon,
  TRONIcon,
  TONIcon,
  WBPIcon,
  USDTTRCIcon,
  OperationDate,
  ArrowIcon,
  ArrowIconActive,
  DataRow,
  AdditionalRow,
  AdditionalRowTd,
  AdditionalInfoBlock,
  AdditionalData,
  OrderStatus,
  StyledTableMobile,
  EmptyLabel,
  DirectionOperation,
  ReportButton,
  CryptoNetwork,
  Label,
} from './styled-ui';
import { Loader } from '../styled-ui';

const getCurrencyLabel = (currency, value) => {
  const formatValue = isCrypto(currency)
    ? parseFloat(value.toFixed(8))
    : value.toFixed(2);
  switch (currency) {
    case CRYPTO_CURRENCIES.BTC:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <BTCIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.ETH:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <ETHIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.USDT:
      return (
        <LabelWrapper>
          <Label>
            <LabelText>{currency}</LabelText>
            <CryptoNetwork>{USDT_NETWORK_TYPES.ERC20}</CryptoNetwork>
          </Label>
          <USDTIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.TRX:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <TRONIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.USDT_TRC:
      return (
        <LabelWrapper>
          <Label>
            <LabelText>{getCurrencyValue(currency)}</LabelText>
            <CryptoNetwork>{USDT_NETWORK_TYPES.TRC20}</CryptoNetwork>
          </Label>
          <USDTTRCIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.TON:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <TONIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case CRYPTO_CURRENCIES.WBP:
      return (
        <LabelWrapper>
          <Label>
            <LabelText>{currency}</LabelText>
            <CryptoNetwork>{USDT_NETWORK_TYPES.TRC20}</CryptoNetwork>
          </Label>
          <WBPIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case FIAT_CURRENCIES.RUB:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <RUBIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case FIAT_CURRENCIES.BYN:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <BYNIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case FIAT_CURRENCIES.USD:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <USDIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    case FIAT_CURRENCIES.EUR:
      return (
        <LabelWrapper>
          <LabelText>{currency}</LabelText>
          <EURIcon />
          <LabelText>{formatValue}</LabelText>
        </LabelWrapper>
      );
    default:
      return <span>{currency}</span>;
  }
};

const OperationsHistoryTable = ({ t }) => {
  const [openedOrders, setOpenedOrders] = useState([]);
  const [ordersViewNow, setOrdersViewNow] = usePersistedState('operations', []);
  const [pagination, setPagination] = useState({});
  const [operationTablePage, setOperationTablePage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getDirectionOperation = (exchangeType, fromSource, toSource) => {
    switch (true) {
      case exchangeType === EXCHANGE_TYPES.BUY &&
        fromSource === EXT_SOURCE &&
        toSource === EXT_SOURCE:
        return t('profile.operationsTable.operations.sellCrypto');
      case exchangeType === EXCHANGE_TYPES.SELL &&
        fromSource === EXT_SOURCE &&
        toSource === EXT_SOURCE:
        return t('profile.operationsTable.operations.buyCrypto');
      case exchangeType === EXCHANGE_TYPES.CRYPTO &&
        fromSource === EXT_SOURCE &&
        toSource === INT_SOURCE:
        return t('profile.operationsTable.operations.replenishmentCrypto');
      case exchangeType === EXCHANGE_TYPES.CRYPTO &&
        fromSource === INT_SOURCE &&
        toSource === EXT_SOURCE:
        return t('profile.operationsTable.operations.outputCrypto');
      case exchangeType === EXCHANGE_TYPES.SELL &&
        fromSource === EXT_SOURCE &&
        toSource === INT_SOURCE:
        return t('profile.operationsTable.operations.buyCryptoInWb');
      case exchangeType === EXCHANGE_TYPES.BUY &&
        fromSource === INT_SOURCE &&
        toSource === EXT_SOURCE:
        return t('profile.operationsTable.operations.sellCryptoFromWb');
      default:
        return null;
    }
  };

  const {
    data: ordersData,
    fetching,
    loaded,
    refetch,
  } = useAxiosStateWithRefetch(
    ordersViewNow?.length > 0
      ? ordersViewNow
      : getOrdersHistoryByUser({ page: operationTablePage })
  );

  useEffect(() => {
    refetch();
  }, [refetch, operationTablePage]);

  useEffect(() => {
    if (ordersData?.content?.length) {
      setOrdersViewNow(ordersData);
      setPagination({
        totalPages: ordersData.totalPages,
        totalElements: ordersData.totalElements,
      });
    }
  }, [ordersData]);

  const onPageChange = page => {
    setOperationTablePage(page - 1);
  };

  const openCloseOrder = number => {
    if (openedOrders.includes(number)) {
      setOpenedOrders(openedOrders.filter(n => n !== number));
    } else {
      setOpenedOrders([...openedOrders, number]);
    }
  };

  const handleOpenOperationsReportModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseOperationsReportModal = () => {
    setIsModalOpen(false);
  };

  const onSubmitHandler = async values => {
    const { start, end } = values;
    const startDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZZ');
    const endDate = moment(end).format('YYYY-MM-DDTHH:mm:ssZZ');

    try {
      const requisite = await getClientOperations({
        start: startDate,
        end: endDate,
      });
      const fileName = pipe(
        pathOr('', ['headers', 'content-disposition']),
        split('='),
        last,
        fileN =>
          fileN.includes('whitebird_client_report_')
            ? fileN
            : 'whitebird_client_report.pdf'
      )(requisite);
      fileDownload(fileName, requisite.data);
      setIsModalOpen(false);
    } catch (e) {
      setIsModalOpen(true);
    }
  };

  if (
    !ordersViewNow?.content?.length &&
    !ordersData?.content?.length &&
    loaded &&
    !fetching
  ) {
    return <EmptyLabel>{t('profile.operationsTable.empty')}</EmptyLabel>;
  }

  const getAdditionalInfo = order => (
    <AdditionalInfoBlock isActive={openedOrders.includes(order?.id)}>
      {order?.creationDate && (
        <AdditionalData>
          {t('profile.operationsTable.additionalInfo.date', {
            value: moment(order.creationDate)
              .locale(getUserLanguage())
              .format('DD MMMM YYYY, HH:mm'),
          })}
        </AdditionalData>
      )}
      {!isNil(order?.exchangeOperation?.inputAsset) &&
        order?.exchangeOperation?.currencyPair?.fromCurrency && (
          <AdditionalData>
            {t('profile.operationsTable.additionalInfo.writeOffAmount', {
              value: isCrypto(order.exchangeOperation.currencyPair.fromCurrency)
                ? order.exchangeOperation.inputAsset
                : order.exchangeOperation.inputAsset.toFixed(2),
            })}
          </AdditionalData>
        )}
      {!isNil(order?.exchangeOperation?.outputAsset) &&
        order.exchangeOperation.currencyPair.toCurrency && (
          <AdditionalData>
            {t('profile.operationsTable.additionalInfo.receiptAmount', {
              value: isCrypto(order.exchangeOperation.currencyPair.toCurrency)
                ? order.exchangeOperation.outputAsset
                : order.exchangeOperation.outputAsset.toFixed(2),
            })}
          </AdditionalData>
        )}
      {order?.cryptoTransaction?.transactionHash && (
        <AdditionalData>
          {t('profile.operationsTable.additionalInfo.hash', {
            value: order.cryptoTransaction.transactionHash,
          })}
        </AdditionalData>
      )}
      {order?.fromSource && order?.toSource && order?.exchangeType && (
        <AdditionalData>
          {t('profile.operationsTable.additionalInfo.type', {
            value: getDirectionOperation(
              order.exchangeType,
              order.fromSource,
              order.toSource
            ),
          })}
        </AdditionalData>
      )}
      {order?.fiatTransaction?.post && (
        <AdditionalData>
          {t('profile.operationsTable.additionalInfo.card', {
            value: convertCardNumber(order.fiatTransaction.post),
          })}
        </AdditionalData>
      )}
    </AdditionalInfoBlock>
  );

  return (
    <>
      {ordersViewNow?.content?.length && (
        <>
          <ReportButton type="button" onClick={handleOpenOperationsReportModal}>
            <IconReport />
            <div>{t('profile.operationsTable.report')}</div>
          </ReportButton>
          <StyledTable>
            <thead>
              <tr>
                <TableHead>
                  {t('profile.operationsTable.headings.id')}
                </TableHead>
                <TableHead>
                  {t('profile.operationsTable.headings.status')}
                </TableHead>
                <TableHead>
                  {t('profile.operationsTable.headings.inputAsset')}
                </TableHead>
                <TableHead> </TableHead>
                <TableHead>
                  {t('profile.operationsTable.headings.outputAsset')}
                </TableHead>
                <TableHead>
                  {t('profile.operationsTable.headings.operationDate')}
                </TableHead>
                <TableHead> </TableHead>
              </tr>
            </thead>
            <tbody>
              {ordersViewNow?.content?.map(order => (
                <Fragment key={order?.id}>
                  <DataRow onClick={() => openCloseOrder(order?.id)}>
                    <TableBody isId>
                      <DirectionOperation>
                        {getDirectionOperation(
                          order?.exchangeType,
                          order?.fromSource,
                          order?.toSource
                        )}
                      </DirectionOperation>
                      #{order?.number}
                    </TableBody>
                    <TableBody>
                      <OrderStatus status={order?.status}>
                        {t(`profile.operationsTable.statuses.${order?.status}`)}
                      </OrderStatus>
                    </TableBody>
                    <TableBody>
                      {getCurrencyLabel(
                        order?.exchangeOperation?.currencyPair?.fromCurrency,
                        order?.exchangeOperation?.inputAsset
                      )}
                    </TableBody>
                    <TableBody>
                      <ArrowIcon />
                    </TableBody>
                    <TableBody>
                      {getCurrencyLabel(
                        order?.exchangeOperation?.currencyPair?.toCurrency,
                        order?.exchangeOperation?.outputAsset
                      )}
                    </TableBody>
                    <TableBody>
                      <OperationDate>
                        {moment(order?.creationDate)
                          .locale(getUserLanguage())
                          .format('DD MMMM YYYY, HH:mm')}
                      </OperationDate>
                    </TableBody>
                    <TableBody>
                      <ArrowIconActive
                        $isActive={openedOrders.includes(order?.id)}
                      />
                    </TableBody>
                  </DataRow>
                  <AdditionalRow>
                    <AdditionalRowTd>
                      {getAdditionalInfo(order)}
                    </AdditionalRowTd>
                  </AdditionalRow>
                </Fragment>
              ))}
            </tbody>
          </StyledTable>
          <StyledTableMobile>
            <thead>
              <tr>
                <TableHead>
                  {t('profile.operationsTable.headings.dataMobile')}
                </TableHead>
                <TableHead>
                  {t('profile.operationsTable.headings.sumMobile')}
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {ordersViewNow?.content?.map(order => (
                <Fragment key={order?.id}>
                  <DataRow onClick={() => openCloseOrder(order?.id)}>
                    <TableBody isId>
                      <DirectionOperation>
                        {getDirectionOperation(
                          order?.exchangeType,
                          order?.fromSource,
                          order?.toSource
                        )}
                      </DirectionOperation>
                      #{order.number}
                      <OrderStatus status={order?.status}>
                        {t(`profile.operationsTable.statuses.${order?.status}`)}
                      </OrderStatus>
                      <OperationDate>
                        {moment(order?.creationDate)
                          .locale(getUserLanguage())
                          .format('DD MMMM YYYY, HH:mm')}
                      </OperationDate>
                    </TableBody>
                    <TableBody>
                      <div>
                        {getCurrencyLabel(
                          order?.exchangeOperation?.currencyPair?.fromCurrency,
                          order?.exchangeOperation?.inputAsset
                        )}
                        {getCurrencyLabel(
                          order?.exchangeOperation?.currencyPair?.toCurrency,
                          order?.exchangeOperation?.outputAsset
                        )}
                      </div>
                      <ArrowIconActive
                        $isActive={openedOrders.includes(order?.id)}
                      />
                    </TableBody>
                  </DataRow>
                  <AdditionalRow>
                    <AdditionalRowTd>
                      {getAdditionalInfo(order)}
                    </AdditionalRowTd>
                  </AdditionalRow>
                </Fragment>
              ))}
            </tbody>
          </StyledTableMobile>
          <Pagination
            totalPages={pagination?.totalPages}
            totalRecords={pagination?.totalElements}
            onPageChanged={onPageChange}
          />
        </>
      )}
      {!loaded && fetching && <Loader />}
      {isModalOpen && (
        <SimpleModal
          Component={OperationReportModal}
          onClose={handleCloseOperationsReportModal}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  );
};

export default OperationsHistoryTable;
