import styled from 'styled-components';
import device from 'device';

export const Title = styled.div`
  margin-top: 45px;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: ${props => props.theme.fullBlackText};

  span {
    margin-left: 6px;
    color: #a8a8a8;
  }

  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: ${({ isHistoryEmpty }) => (isHistoryEmpty ? 'relative' : 'static')};
`;

export const THead = styled.thead`
  tr {
    background: #f7f7f8;
  }
`;

export const TBody = styled.tbody`
  tr:nth-child(even) {
    background: #f7f7f8;

    td:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    td:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
`;

export const TH = styled.th`
  width: 10%;
  height: 35px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding: 0;
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.fullBlackText};

  &:first-child {
    padding-left: 25px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    padding-right: 25px;
    text-align: right;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  @media ${device.mobile920} {
    font-size: 12px;
    &:first-child {
      padding-left: 5px;
    }

    &:last-child {
      padding-right: 5px;
    }
  }

  @media ${device.tablet} {
    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
`;

export const TD = styled.td`
  height: 35px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.fullBlackText};
  white-space: nowrap;
  padding: 0;
  margin: 0;

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    padding-right: 25px;
    text-align: right;
  }

  @media ${device.mobile920} {
    font-size: 12px;
    &:first-child {
      padding-left: 5px;
    }

    &:last-child {
      padding-right: 5px;
    }
  }

  @media ${device.tablet} {
    height: 40px;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
`;

export const Currency = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.fullBlackText};
`;

export const Network = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-left: 5px;
  letter-spacing: -0.01em;
  color: #a8a8a8;
`;

export const Status = styled(TD)`
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ success }) => (success ? '#6ADF4C' : '#DF4C4C')};
  border-radius: 50%;
  margin-right: 10px;

  @media ${device.tablet} {
    margin-right: 5px;
  }
`;

export const HistoryNotFound = styled(TD)`
  position: absolute;
  right: 0;
  left: 0;
  font-size: 16px;
  color: ${props => props.theme.fullBlackText};
  width: max-content;
  margin: 20px auto;
`;
