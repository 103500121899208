import React from 'react';
import { ReactComponent as BTC } from 'assets/images/icons/BTC.svg';
import { ReactComponent as ETH } from 'assets/images/icons/ETH.svg';
import { ReactComponent as USD } from 'assets/images/icons/USD.svg';
import { ReactComponent as USDT } from 'assets/images/icons/USDT.svg';
import { ReactComponent as BYN } from 'assets/images/icons/BYN.svg';
import { ReactComponent as EUR } from 'assets/images/icons/EUR.svg';
import { ReactComponent as RUB } from 'assets/images/icons/RUB.svg';
import { ReactComponent as TRX } from 'assets/images/icons/TRON.svg';
import { ReactComponent as TON } from 'assets/images/icons/TON.svg';
import { ReactComponent as WBP } from 'assets/images/icons/WBP.svg';

export const REQUEST = 'REQUEST';
export const PROCESSING = 'PROCESSING';
export const CHANGED = 'CHANGED';
export const PAUSED = 'PAUSED';
export const CONFIRMED = 'CONFIRMED';
export const REJECTED = 'REJECTED';
export const EXPIRED = 'EXPIRED';
export const DECLINED = 'DECLINED';
export const currencies = [
  'BTC',
  'ETH',
  'USDT',
  'TRX',
  'USDT_TRC',
  'TON',
  'WBP',
  'BYN',
  'USD',
  'RUB',
  'EUR',
];
export const currenciesLogo = {
  BTC: <BTC />,
  ETH: <ETH />,
  USDT: <USDT />,
  USDT_TRC: <USDT />,
  BYN: <BYN />,
  USD: <USD />,
  RUB: <RUB />,
  EUR: <EUR />,
  TRX: <TRX />,
  TON: <TON />,
  WBP: <WBP />,
};
export const cryptoCurrencies = [
  'BTC',
  'ETH',
  'USDT',
  'TRX',
  'USDT_TRC',
  'TON',
  'WBP',
];
export const fiatCurrencies = ['BYN', 'USD', 'RUB', 'EUR'];
export const FIAT_CURRENCIES = {
  BYN: 'BYN',
  RUB: 'RUB',
  USD: 'USD',
  EUR: 'EUR',
};
export const CRYPTO_CURRENCIES = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDT: 'USDT',
  TRX: 'TRX',
  USDT_TRC: 'USDT_TRC',
  TON: 'TON',
  WBP: 'WBP',
};
export const INITIAL_VALUE_CURRENCIES = {
  BTC: '0,015',
  ETH: '0,1',
  USDT: '1000',
  TRX: '100',
  USDT_TRC: '1000',
  TON: '100',
  WBP: '1000',
  BYN: '2800',
  RUB: '80000',
  USD: '1000',
  EUR: '1000',
  DEFAULT: '1000',
};
export const USDT_NETWORK_TYPES = { ERC20: 'ERC20', TRC20: 'TRC20' };
export const EXCHANGE_TYPES = { BUY: 'BUY', SELL: 'SELL', CRYPTO: 'CRYPTO' };
export const EXT_SOURCE = 'EXT';
export const INT_SOURCE = 'INT';
export const REGULA = 'REGULA';
export const SUMSUB = 'SUMSUB';
export const VERIFICATION_TYPES = [REGULA, SUMSUB];
