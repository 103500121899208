/* eslint-disable no-nested-ternary */
import React, { useEffect, useContext } from 'react';
import { Formik } from 'formik';
import requestErrors from 'utils/constants/requestErrors';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import ClientContext from 'utils/contexts/Client';
import { isCrypto } from 'utils/crypto';
import validationFieldsValue from 'components/Output/validation';
import OutputCalculator from './OutputCalculator';
import { fromLocaleNumber } from '../../utils/number';

const Output = ({ refresh, isOrderUpdated }) => {
  const { t } = useTranslation();
  const client = useContext(ClientContext);
  const clientStatus = pathOr('', ['data', 'status'], client);

  const setCurrency = (setValues, setTouched, values) => (name, newValue) => {
    const currency = name.includes('currency') && newValue;

    if (isCrypto(localStorage.getItem('from_currency'))) {
      localStorage.setItem('from_currency', currency);
    } else {
      localStorage.setItem('to_currency', currency);
    }

    setValues({
      ...values,
      calculation: {
        inputAsset: values.calculation.inputAsset,
      },
      currency: newValue,
    });
  };

  const stateSubmitOrdersOutput = useAxiosSubmitingEffect({
    method: 'post',
    url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/crypto/withdrawal`,
  });

  const onSubmitHandler = (values, actions) => {
    const { calculation: calc, currency, externalCryptoAddress } = values;

    const requestData = {
      calculation: {
        inputAsset: fromLocaleNumber(calc.inputAsset),
      },
      currency,
      externalCryptoAddress,
    };
    stateSubmitOrdersOutput.setFormAndSubmit({
      values: requestData,
      actions,
    });
  };
  useEffect(() => {
    if (stateSubmitOrdersOutput.loaded) {
      if (isOrderUpdated) {
        stateSubmitOrdersOutput.form.actions.setSubmitting(false);
      }
      const { actions } = stateSubmitOrdersOutput.form;
      if (stateSubmitOrdersOutput.error) {
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        if (stateSubmitOrdersOutput.error.status === 'NOT_FOUND') {
          refresh();
        }
        if (stateSubmitOrdersOutput.error.status === 'INVALID_ADDRESS') {
          actions.setErrors({
            externalCryptoAddress: t(
              'calculationView.errors.validationErrorWalletFormat'
            ),
          });
        }
        if (
          Object.keys(requestErrors).includes(
            stateSubmitOrdersOutput.error.status
          )
        ) {
          actions.setErrors({
            orderCreation: {
              message: t(
                `calculationView.errors.responseErrors.${stateSubmitOrdersOutput.error.status}`,
                { operation: 'вывода' }
              ),
            },
          });
        }
      } else {
        refresh();
      }
    }
    if (!stateSubmitOrdersOutput.error && stateSubmitOrdersOutput.form) {
      const { actions } = stateSubmitOrdersOutput.form;
      actions.setErrors({});
    }
  }, [
    refresh,
    stateSubmitOrdersOutput,
    stateSubmitOrdersOutput.loaded,
    stateSubmitOrdersOutput.error,
    isOrderUpdated,
    t,
  ]);

  const initialValues = {
    calculation: {
      inputAsset: 0,
    },
    currency: isCrypto(localStorage.getItem('from_currency'))
      ? localStorage.getItem('from_currency')
      : isCrypto(localStorage.getItem('to_currency'))
      ? localStorage.getItem('to_currency')
      : 'BTC',
    externalCryptoAddress: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validationFieldsValue(clientStatus, t)}
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        touched,
        setValues,
        setTouched,
        validateForm,
        errors,
        ...props
      }) => (
        <OutputCalculator
          values={values}
          setValues={setValues}
          touched={touched}
          errors={errors}
          validateForm={validateForm}
          {...props}
          setCurrency={setCurrency(setValues, setTouched, values, touched)}
        />
      )}
    </Formik>
  );
};

export default Output;
