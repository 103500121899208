import PropTypes from 'prop-types';
import React from 'react';
import { ExtraSmallButton } from 'ui-kit/Button/index';
import { SwitcherWrapper, Switcher } from './styled-ui';

const ViewSwitcher = ({
  t,
  value,
  setPageView,
  profileTabs,
  isDisplayWbWallet,
}) => (
  <SwitcherWrapper>
    <Switcher isDisplayWbWallet={isDisplayWbWallet}>
      {profileTabs.map(type => (
        <ExtraSmallButton
          isMedia
          key={type}
          name="mediaType"
          onClick={() => setPageView(type)}
          type="button"
          active={value === type}
        >
          {t(`profile.pageTabs.${type}`)}
        </ExtraSmallButton>
      ))}
    </Switcher>
  </SwitcherWrapper>
);

ViewSwitcher.propTypes = {
  t: PropTypes.func.isRequired,
  setPageView: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ViewSwitcher;
