export const generateReferralLink = `${process.env.REACT_APP_EXCHANGE_URL}/referrer/generate`;

export const getReferrer = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/referrer/get`,
};

export const getFeeTransactions = ({ page }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ACCOUNTING_URL}/transactions/fee/paged?page=${page}&size=20&sort=creationDate,desc`,
});

export const getWithdrawalTransactions = ({ page }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ACCOUNTING_URL}/transactions/withdrawal/paged?page=${page}&size=20&sort=creationDate,desc`,
});

export const withdrawBalance = `${process.env.REACT_APP_ACCOUNTING_URL}/transactions/withdrawal/add`;

export const getReferralCount = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/referrer/referral-count`,
};

export const updateReferralCurentAccount = `${process.env.REACT_APP_EXCHANGE_URL}/referrer/account`;
