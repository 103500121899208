import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { logout } from 'utils/services/request/auth';
import { getPathRoute } from 'utils/location';
import MediaPreloader from 'ui-kit/LoadingPlaceholder/media';
import NotFoundLayout from 'components/Layouts/404';
import FaqComponent from 'components/Faq';
import HomeLayout from 'components/Layouts/home';
import DashboardLayout from 'components/Layouts/dashboad';
import CustomLayout from 'components/Layouts/custom';
import Support from 'components/Support';
import ConfirmEmail from 'components/Auth/ConfirmEmail';
import BelarusbankComponent from 'components/Belarusbank';
import GoogleReCaptchaWrapper from 'components/GoogleReCaptchaWrapper';
import Signin from 'pages/Signin';
import Signup from 'pages/Signup';
import RestorePassword from 'pages/RestorePassword';
import NoMatch from 'pages/NoMatch/index';
import UserProfile from 'pages/UserProfile';
import DisputeComponent from 'pages/Dispute';
import TransferConfirm from 'pages/TransferConfirm';
import AttachCard from 'pages/AttachCard';
import OAuth from 'pages/OAuth';
import MemberMeetingInformation from 'pages/MemberMeetingInformation';
import {
  BELARUSBANK,
  DISPUTE,
  FAQ,
  HOME,
  LOGOUT,
  RESTORE_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  SUPPORT,
  MEDIA,
  MEDIA_VIEW,
  CONFIRM,
  USER_PROFILE,
  TRANSFER,
  AUTHORIZE,
  ATTACH_CARD,
  INFORMATION,
} from 'constants/routings';

const Blog = lazy(() => import('components/Blog'));
const MediaView = lazy(() => import('components/MediaView'));

const SignupComponent = () => GoogleReCaptchaWrapper(Signup);
const SupportComponent = () => GoogleReCaptchaWrapper(Support);
const UserProfileComponent = () => GoogleReCaptchaWrapper(UserProfile);

const BlogComponent = () => (
  <Suspense fallback={<MediaPreloader />}>
    <Blog />
  </Suspense>
);

const MediaViewComponent = ({ match }) => (
  <Suspense fallback={<MediaPreloader mediaView />}>
    <MediaView match={match} />
  </Suspense>
);

const PublicRoute = () => {
  return (
    <>
      <Helmet>
        <title>
          Купить / Продать криптовалюту в Беларуси онлайн за рубли | Whitebird
        </title>
        <meta
          name="og:image"
          content="https://whitebird.io/assets/images/main-logo.svg"
        />
        <meta
          name="description"
          content="У нас вы можете купить криптовалюту в Беларуси за рубли с использованием банковской карточки. Покупайте или продавайте Bitcoin быстро и по оптимальному курсу. Мгновенный и безопасный обмен. Регулируемая деятельность."
        />
        <meta
          property="og:title"
          content="Купить / Продать криптовалюту в Беларуси онлайн за рубли | Whitebird"
        />
        <meta
          property="og:description"
          content="У нас вы можете купить криптовалюту в Беларуси за рубли с использованием банковской карточки. Покупайте или продавайте Bitcoin быстро и по оптимальному курсу. Мгновенный и безопасный обмен. Регулируемая деятельность."
        />
      </Helmet>
      <Switch>
        <Route exact path={getPathRoute(SIGN_IN)} component={Signin} />
        <Route exact path={getPathRoute(SIGN_UP)} component={SignupComponent} />
        <Route exact path={getPathRoute(CONFIRM)} component={ConfirmEmail} />
        <Route exact path={getPathRoute(AUTHORIZE)} component={OAuth} />
        <Route
          exact
          path={getPathRoute(TRANSFER)}
          component={TransferConfirm}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(SUPPORT)}
          component={SupportComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(FAQ)}
          component={FaqComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(MEDIA)}
          component={BlogComponent}
        />
        <RouteWithLayout
          exact
          layout={CustomLayout}
          path={getPathRoute(MEDIA_VIEW)}
          component={MediaViewComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(DISPUTE)}
          component={DisputeComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(BELARUSBANK)}
          component={BelarusbankComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(USER_PROFILE)}
          component={UserProfileComponent}
        />
        <RouteWithLayout
          exact
          layout={DashboardLayout}
          path={getPathRoute(INFORMATION)}
          component={MemberMeetingInformation}
        />
        <Route exact path={getPathRoute(LOGOUT)} component={logout} />
        <Route exact path={getPathRoute(HOME)} component={HomeLayout} />
        <Route
          exact
          path={getPathRoute(RESTORE_PASSWORD)}
          component={RestorePassword}
        />
        <Route exact path={getPathRoute(ATTACH_CARD)} component={AttachCard} />
        <NotFoundLayout>
          <Route component={NoMatch} />
        </NotFoundLayout>
      </Switch>
    </>
  );
};

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        React.createElement(
          layout,
          props,
          React.createElement(component, props)
        )
      }
    />
  );
};

export default PublicRoute;
