import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import '@regulaforensics/vp-frontend-face-components';
import { Button } from 'ui-kit/Button';
import { useTranslation } from 'react-i18next';
import observerWC from 'utils/webComponents';
import { FACE_SDK_ACTIONS, REGULA_MODES } from 'utils/constants/regula';
import { stylesRegulaComponent } from 'components/Regula/utils';
import videoFile from 'assets/video/facesdk.mp4';
import axios from 'axios';
import { getLivenessComplete } from 'utils/services/request/verifications';
import {
  FaceLivenessWrapper,
  LivenessConditions,
  LivenessStartScreen,
  LivenessSubTitle,
  LivenessTitle,
  Video,
} from './styled-ui';

const FaceLiveness = ({ callbackProps, operatingMode }) => {
  const { t } = useTranslation();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isComponentOpen, setIsComponentOpen] = useState(false);
  const containerRef = useRef(null);
  const livenessRef = useRef(null);
  const isChangeDocs = operatingMode === REGULA_MODES.changeDocuments;
  const isChangeReg = operatingMode === REGULA_MODES.changeRegistration;

  const listener = useCallback(
    event => {
      if (event.detail.action === FACE_SDK_ACTIONS.PROCESS_FINISHED) {
        const status = event.detail?.data?.response?.status;
        if (status === 0) {
          const { transactionId } = event.detail.data.response;
          axios
            .get(`${getLivenessComplete}/?transactionId=${transactionId}`)
            .then(res => {
              const { portrait } = res.data;
              if (callbackProps.setLivenessResult) {
                callbackProps.setLivenessResult(portrait);
              }
            });
          setTimeout(() => {
            setIsNextDisabled(false);
            setIsComponentOpen(false);
          }, 2000);
        }
      }
      if (event.detail.action === FACE_SDK_ACTIONS.CLOSE) {
        setIsComponentOpen(false);
      }
    },
    [callbackProps]
  );

  useEffect(() => {
    const containerLiveness = containerRef.current;
    containerLiveness.addEventListener('face-liveness', listener);
    // eslint-disable-next-line consistent-return
    return () => {
      containerLiveness.removeEventListener('face-liveness', listener);
    };
  }, [listener]);

  useLayoutEffect(() => {
    const livenessRefCurrent = livenessRef.current;
    if (!livenessRefCurrent) return;
    if (livenessRefCurrent.shadowRoot) {
      observerWC.observe(livenessRefCurrent.shadowRoot, {
        childList: true,
        subtree: true,
      });
    }
    livenessRefCurrent.settings = {
      locale: 'ru',
      recordingProcess: 2,
      url: process.env.REACT_APP_LIVENESS_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      startScreen: false,
      finishScreen: true,
      closeDisabled: false,
      changeCamera: true,
      copyright: false,
      customization: stylesRegulaComponent,
    };
    livenessRefCurrent.translations = {
      ru: {
        goButton: t('verification.regula.liveness.goButton'),
      },
    };
  }, [isComponentOpen, t]);

  const doneButtonHandler = () => callbackProps?.nextPage();

  return (
    <div>
      <div ref={containerRef}>
        {isComponentOpen && (
          <fullscreen-container>
            <FaceLivenessWrapper>
              <face-liveness ref={livenessRef} />
            </FaceLivenessWrapper>
          </fullscreen-container>
        )}
      </div>
      {!isComponentOpen && isNextDisabled && (
        <LivenessStartScreen>
          {!isChangeDocs && !isChangeReg && (
            <LivenessTitle>
              {t('verification.regula.liveness.title')}
            </LivenessTitle>
          )}
          <div style={{ textAlign: 'center' }}>
            <h3>
              {isChangeDocs && t('verification.regula.modes.changeDocs')}
              {isChangeReg && t('verification.regula.modes.changeReg')}
            </h3>
            <h4>
              {(isChangeDocs || isChangeReg) &&
                t('verification.regula.modes.title')}
              {isChangeDocs && t('verification.regula.modes.titleDocs')}
              {isChangeReg && t('verification.regula.modes.titleReg')}
            </h4>
          </div>
          <LivenessConditions>
            <div>
              <LivenessSubTitle>
                {t('verification.regula.liveness.subTitle')}
              </LivenessSubTitle>
              <ul>
                <li>{t('verification.regula.liveness.conditionFirst')}</li>
                <li>{t('verification.regula.liveness.conditionSecond')}</li>
                <li>{t('verification.regula.liveness.conditionThird')}</li>
              </ul>
            </div>
            <Video src={videoFile} muted loop autoPlay playsInline />
          </LivenessConditions>
          <Button
            width="200px"
            padding="10px"
            type="button"
            onClick={() => setIsComponentOpen(true)}
          >
            {t('verification.startButton')}
          </Button>
        </LivenessStartScreen>
      )}
      {!isNextDisabled && (
        <Button
          width="200px"
          padding="10px"
          type="button"
          disabled={isNextDisabled}
          onClick={doneButtonHandler}
        >
          {t('verification.doneButton')}
        </Button>
      )}
    </div>
  );
};
export default FaceLiveness;
