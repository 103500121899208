import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { pathOr, head } from 'ramda';
import wsConnectInstance from 'ws-connect';
import * as RoutePaths from 'constants/routings';
import { ReactComponent as LogoutComponent } from 'assets/images/icons/logout.svg';
import { ReactComponent as PhoneRing } from 'assets/images/icons/phone-ring.svg';
import { getNotifications } from 'utils/services/request/notifications';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import OrderDetails from 'utils/contexts/OrderDetails';
import NotificationContext from 'utils/contexts/Notification';
import { isUserAnonymous } from 'utils/services/selectors/user';
import { DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import { SETTINGS } from 'utils/constants/profileTabs';
import { USER_PROFILE } from 'constants/routings';
import NotificationPopup from 'components/NotificationPopup';
import { ProfileIcon, VerifiedIcon } from 'components/Header/styled-ui';
import {
  Information,
  SignInLink,
  StyledLink,
  UserExit,
  UserExitButton,
  UserIconWrap,
  UserInformation,
  ProfileText,
  UserInformationDetails,
  UserInformationOverlay,
  UserLabelValue,
  UserVerificationText,
  ProfileInfoWrapper,
  ProfileIconWrapper,
  UserLabelValueLink,
  RingIcon,
  CircleIconBg,
  RingButton,
  UnreadNotificationDot,
  StatusAccount,
  NotificationCount,
  InfoIcon,
  ExchangeLink,
  SwapIcon,
} from './styled-ui';
import './toggle.css';
import { pushUserId, pushAnalyticsEvent } from '../App/analytics';

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'unMount': {
      if (
        wsConnectInstance?.wsConnection?.connected &&
        state &&
        state.unsubscribe
      ) {
        state.unsubscribe();
      }
      break;
    }
    default:
  }
}

const CustomerInformation = ({ isSmallScreen, isNavVisible }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const orderDetails = useContext(OrderDetails);
  const isMerchant = user.data?.roles
    .map(({ name }) => name)
    .includes('MERCHANT');
  const userStatus =
    pathOr('', ['data', 'userStatus'], user) === 'NEW'
      ? 'NEW'
      : pathOr('', ['data', 'status'], client);
  const isPhoneNotificationsEnabled = pathOr(
    false,
    ['data', 'phoneNotificationsEnabled'],
    user
  );
  const statuses = {
    NOT_VERIFIED: t('statuses.notVerified'),
    PENDING: t('statuses.pending'),
    VERIFIED: t('statuses.verified'),
    FROZEN: t('statuses.frozen'),
    MERCHANT: t('statuses.merchant'),
  };
  const inputUserOverlayRef = useRef(null);
  const [isUserOverlayed, setUserOverlay] = useState(false);
  const [userData, setUserData] = useState('');
  const [wsSubscription, dispatch] = useReducer(reducer, null);
  const [additionalSub, setAdditionalSub] = useState(false);

  const [isNotificationOverlayed, setNotificationOverlay] = useState(false);
  const { notificationState, dispatchNotification } = useContext(
    NotificationContext
  );

  const hasUnreadNotifications = !!head(notificationState?.notifications || []);

  const isPhoneNotifications =
    !isPhoneNotificationsEnabled &&
    client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY &&
    client?.data?.status === 'VERIFIED';

  const isUserNotHavePhone =
    !user?.data?.phone &&
    client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY &&
    client?.data?.status === 'VERIFIED';

  useEffect(() => {
    if (
      user.loaded &&
      user.data &&
      !wsConnectInstance?.wsConnection &&
      !wsSubscription &&
      dispatchNotification
    ) {
      getNotifications(0, dispatchNotification);
      wsConnectInstance?.connect(() => {
        wsConnectInstance?.wsConnection?.subscribe(
          '/user/notifications/events',
          message => {
            if (message.body) {
              const messageBody = JSON.parse(message.body);
              dispatchNotification({
                type: 'ADD_NOTIFICATION_BY_SOCKET',
                data: messageBody,
              });
            }
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, wsConnectInstance, additionalSub, dispatchNotification]);

  useEffect(() => {
    setTimeout(() => setAdditionalSub(true), 1000);
    return () => dispatch({ type: 'unMount' });
  }, []);

  useEffect(() => {
    if (user.loaded && user.data && client.loaded && client.data) {
      setUserData(client.data.status);
      pushUserId(user.data.id);
      if (isMerchant) {
        setUserData('MERCHANT');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, isMerchant]);

  const handleClick = e => {
    if (
      inputUserOverlayRef.current &&
      isUserOverlayed &&
      !inputUserOverlayRef.current.contains(e.target)
    ) {
      setUserOverlay(false);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const isAnonymous = isUserAnonymous(user);
  const isUserVerified = userStatus === 'VERIFIED';

  const handleClickSignUp = () => {
    pushAnalyticsEvent('button_registration_click');
  };

  const handleClickSignIn = () => {
    pushAnalyticsEvent('button_login_click');
  };

  const handleClickExchange = () => {
    localStorage.removeItem('tab');
    pushAnalyticsEvent('button_exchange_click');
  };

  const isDisplayExchangeButton =
    !isSmallScreen && !isAnonymous && !orderDetails?.data?.status;

  return (
    <ProfileInfoWrapper>
      {!isNavVisible && !isAnonymous && (
        <RingButton
          type="button"
          isActive={isNotificationOverlayed}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (isUserOverlayed) {
              setUserOverlay(!isUserOverlayed);
            }
            setNotificationOverlay(!isNotificationOverlayed);
          }}
        >
          <CircleIconBg isActive={isNotificationOverlayed} />
          {(hasUnreadNotifications || isUserNotHavePhone) && (
            <UnreadNotificationDot isActive={isNotificationOverlayed}>
              <NotificationCount>
                {isUserNotHavePhone
                  ? '1'
                  : notificationState?.notifications?.length}
              </NotificationCount>
            </UnreadNotificationDot>
          )}
          <RingIcon
            IsBellAnimation={hasUnreadNotifications || isUserNotHavePhone}
            isActive={isNotificationOverlayed}
          />
        </RingButton>
      )}
      {isNotificationOverlayed && (
        <NotificationPopup
          t={t}
          setNotificationOverlay={setNotificationOverlay}
          isUserNotHavePhone={isUserNotHavePhone}
        />
      )}
      {!isNavVisible && isSmallScreen && !isAnonymous && (
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (isNotificationOverlayed) {
              setNotificationOverlay(!isNotificationOverlayed);
            }
            setUserOverlay(!isUserOverlayed);
          }}
        >
          <ProfileIconWrapper>
            <ProfileIcon />
            {isUserVerified && <VerifiedIcon />}
          </ProfileIconWrapper>
        </button>
      )}
      {isAnonymous && !isSmallScreen && (
        <>
          <SignInLink
            margin="0 15px"
            onClick={handleClickSignUp}
            to={RoutePaths.SIGN_UP}
          >
            {t('customer-info.registration')}
          </SignInLink>
          <SignInLink onClick={handleClickSignIn} to={RoutePaths.SIGN_IN}>
            {t('customer-info.signin')}
          </SignInLink>
        </>
      )}
      {isDisplayExchangeButton && (
        <ExchangeLink onClick={handleClickExchange} to={RoutePaths.HOME}>
          <SwapIcon />
          {t('customer-info.exchange')}
        </ExchangeLink>
      )}
      {!isAnonymous && (
        <Information>
          {!isSmallScreen && (
            <UserInformation
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (isNotificationOverlayed) {
                  setNotificationOverlay(!isNotificationOverlayed);
                }
                setUserOverlay(!isUserOverlayed);
              }}
            >
              <ProfileIconWrapper>
                <ProfileIcon isUserVerified={isUserVerified} />
                {isUserVerified && <VerifiedIcon />}
                <ProfileText>{t('header.myAccount')}</ProfileText>
              </ProfileIconWrapper>
            </UserInformation>
          )}
          {!isUserOverlayed ? null : (
            <UserInformationOverlay ref={inputUserOverlayRef}>
              <UserInformationDetails
                isVerified={isUserVerified}
                isInfo={isUserNotHavePhone}
              >
                {!isUserVerified &&
                  (userStatus === 'NOT_VERIFIED' ? (
                    <StatusAccount status={userData}>
                      <UserLabelValueLink to={RoutePaths.VERIFICATIONS}>
                        <UserLabelValue>
                          {isUserVerified && <VerifiedIcon userView />}
                          <UserVerificationText status={userData}>
                            {statuses[userData]}
                          </UserVerificationText>
                        </UserLabelValue>
                      </UserLabelValueLink>
                    </StatusAccount>
                  ) : (
                    <StatusAccount status={userData}>
                      <UserLabelValue>
                        {isUserVerified && <VerifiedIcon userView />}
                        <UserVerificationText status={userData}>
                          {statuses[userData]}
                        </UserVerificationText>
                      </UserLabelValue>
                    </StatusAccount>
                  ))}
                <StyledLink
                  to={{
                    pathname: USER_PROFILE,
                    search: `?tab=${SETTINGS}`,
                  }}
                >
                  <UserLabelValue>
                    <UserIconWrap>
                      <ProfileIcon userView />
                    </UserIconWrap>
                    {t('profile.settings.profile')}
                  </UserLabelValue>
                </StyledLink>
                {isPhoneNotifications && (
                  <StyledLink
                    to={{
                      pathname: USER_PROFILE,
                      search: `?tab=${SETTINGS}`,
                    }}
                  >
                    <UserLabelValue>
                      <UserIconWrap>
                        <PhoneRing />
                      </UserIconWrap>
                      {t('profile.settings.turnOnMobileNotifications')}
                    </UserLabelValue>
                  </StyledLink>
                )}
                {isUserNotHavePhone && (
                  <StyledLink
                    to={{
                      pathname: USER_PROFILE,
                      search: `?tab=${SETTINGS}`,
                    }}
                  >
                    <UserLabelValue isInfo={isUserNotHavePhone}>
                      <UserIconWrap>
                        <InfoIcon />
                      </UserIconWrap>
                      {t('profile.settings.addPersonalData')}
                    </UserLabelValue>
                  </StyledLink>
                )}
                <StyledLink to={RoutePaths.LOGOUT}>
                  <UserExit>
                    <UserExitButton>
                      <LogoutComponent />{' '}
                      {t('customer-info.exist-from-account')}
                    </UserExitButton>
                  </UserExit>
                </StyledLink>
              </UserInformationDetails>
            </UserInformationOverlay>
          )}
        </Information>
      )}
    </ProfileInfoWrapper>
  );
};

export default CustomerInformation;
