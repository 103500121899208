import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans } from 'react-i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'ramda';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { SIGN_IN } from 'constants/routings';
import ConfirmationPhone from 'components/ConfirmationPhone';
import { getInputMask, getCodeByCountry } from 'components/Auth/helpers';
import { pushAnalyticsEvent } from 'components/App/analytics';
import { FormGroup } from 'components/Verification/styled-ui';
import ErrorModal from 'components/ErrorModal';
import { getPartner } from 'utils/location';
import { updateAxiosDefault } from 'utils/axiosUtil';
import {
  useAxiosSubmitingEffect,
  useAxiosSubmitingEffectWithHeaders,
} from 'utils/hooks/axiosHook';
import {
  loginFormOptions,
  setRegistrationPhone,
  setRegistrationPhoneVerify,
  registerFromOptions,
  getEmailStatus,
} from 'utils/services/request/auth';
import { COUNTRY_CODES } from 'utils/countryCodes';
import GeoContext from 'utils/contexts/Geo';
import { setToken } from 'utils/services/request/localStorage';
import { ERROR_MESSAGES, ERROR_STATUSES } from 'utils/constants/errors';
import { Button } from 'ui-kit/Button';
import ReactIsCapsLockActive from 'ui-kit/CapsLock';
import CustomCheckbox from 'ui-kit/CustomField/Checkbox';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import CountryCodeSelect from 'ui-kit/CountryCodeSelect';
import PhoneInput from 'ui-kit/PhoneInput';
import {
  Adding,
  Agreements,
  CloseEyeIcon,
  FormGrid,
  Header,
  Info,
  InfoPartner,
  OpenEyeIcon,
  WrapEye,
  ButtonWrapper,
} from '../styled-ui';

const signUpSchema = t =>
  Yup.object().shape({
    registrationEmail: Yup.string()
      .email(t('signUpForm.errors.incorrectEmailFormat'))
      .required(t('signUpForm.errors.requiredField')),

    password: Yup.string()
      .required(t('signUpForm.errors.requiredField'))
      .min(12, t('signUpForm.errors.passMin', { count: 12 }))
      .max(128, t('signUpForm.errors.passMax', { count: 128 })),

    mainAgreement: Yup.boolean().oneOf(
      [true],
      t('signUpForm.errors.requiredField')
    ),

    agreements: Yup.boolean().oneOf(
      [true],
      t('signUpForm.errors.requiredField')
    ),
    phone: Yup.string()
      .required(t('profile.requiredField'))
      .test('phone-validation', t('profile.phoneValidation'), function(value) {
        try {
          const countryCode = this.resolve(Yup.ref('countryCode'));
          const phoneUtil = PhoneNumberUtil.getInstance();
          const isValid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(value, countryCode),
            countryCode
          );
          return isValid;
        } catch (e) {
          return false;
        }
      }),
  });

const SignUp = ({ user, t }) => {
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const partnerName = getPartner(location);
  const geo = useContext(GeoContext);
  const formikRef = useRef();

  const initialValues = {
    registrationEmail: '',
    password: '',
    mainAgreement: true,
    agreements: true,
    promoAgreement: true,
    countryCode: getCodeByCountry(geo?.data?.country),
    phone: '',
  };

  const [isShowPassword, setShowPassword] = useState(false);
  const [agreements, setAgreements] = useState({
    isMainAgree: true,
    isRuleAgree: true,
    isPromoAgree: true,
  });
  const [countryCode, setCountryCode] = useState('');
  const [isPhoneConfirmModal, setIsPhoneConfirmModal] = useState(false);
  const [data, setData] = useState(initialValues);
  const [verificationActions, setVerificationActions] = useState(null);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneVerifyError, setIsPhoneVerifyError] = useState(false);
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);
  const [tokenGoogle, setTokenGoogle] = useState('');
  const stateSubmittingRegisterForm = useAxiosSubmitingEffectWithHeaders(
    registerFromOptions
  );
  const stateSubmitingUserPhone = useAxiosSubmitingEffectWithHeaders(
    setRegistrationPhone
  );
  const stateSubmittingLoginForm = useAxiosSubmitingEffect(loginFormOptions);

  const handleReCaptchaVerify = useCallback(
    tokenS => {
      if (tokenGoogle) return;
      setTokenGoogle(tokenS);
    },
    [setTokenGoogle, tokenGoogle]
  );

  const onSuccessCallback = async () => {
    const result = await executeRecaptcha('signUp');
    // eslint-disable-next-line no-param-reassign
    const requestData = {
      password: data.password,
      agreements: data.agreements,
      phone: isPhoneVerifyError ? '' : data.phone,
      email: data.registrationEmail,
      promoAgreement: data.promoAgreement,
    };
    stateSubmittingRegisterForm.setFormAndSubmit({
      headers: {
        recaptcha: result,
      },
      values: requestData,
      actions: verificationActions,
    });
  };

  useEffect(() => {
    if (stateSubmittingRegisterForm.loaded) {
      if (stateSubmittingRegisterForm.error) {
        const { actions } = stateSubmittingRegisterForm.form;
        actions.setStatus(undefined);
        actions.setSubmitting(false);
        if (stateSubmittingRegisterForm.error.status === 'USER_EXISTS') {
          actions.setFieldError(
            'registrationEmail',
            t('signUpForm.errors.userExists')
          );
        } else if (
          stateSubmittingRegisterForm.error.status ===
          ERROR_STATUSES.INVALID_EMAIL
        ) {
          if (
            stateSubmittingRegisterForm.error.message ===
            ERROR_MESSAGES.USER_EXIST
          ) {
            actions.setFieldError(
              'registrationEmail',
              t('signUpForm.errors.userExists')
            );
          } else {
            actions.setFieldError(
              'registrationEmail',
              t('signUpForm.errors.incorrectEmailFormat')
            );
          }
        }
        if (stateSubmittingRegisterForm.error.status === 'INVALID_AGREEMENT') {
          actions.setFieldError(
            'agreements',
            t('signUpForm.errors.requiredField')
          );
        }
      } else {
        const loginData = new FormData();
        loginData.append('grant_type', 'password');
        loginData.append(
          'username',
          stateSubmittingRegisterForm.form.values.email
            ? stateSubmittingRegisterForm.form.values.email.toLowerCase()
            : ``
        );
        loginData.append(
          'password',
          stateSubmittingRegisterForm.form.values.password
        );
        stateSubmittingLoginForm.setFormAndSubmit({
          values: loginData,
        });
      }
    } else if (stateSubmittingRegisterForm.fetching) {
      const { actions } = stateSubmittingRegisterForm.form;
      actions.setSubmitting(true);
      actions.setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingRegisterForm, stateSubmittingRegisterForm.loaded]);

  useEffect(() => {
    if (stateSubmittingLoginForm.loaded) {
      if (!stateSubmittingLoginForm.error) {
        setToken(stateSubmittingLoginForm.data);
        pushAnalyticsEvent('registration_step1');
        updateAxiosDefault();
        user.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingLoginForm, stateSubmittingLoginForm.loaded]);

  useEffect(() => {
    if (stateSubmitingUserPhone.loaded) {
      const { actions } = stateSubmitingUserPhone.form;
      if (stateSubmitingUserPhone.error) {
        const { status, message } = stateSubmitingUserPhone.error;
        if (status === ERROR_STATUSES.INVALID_CODE) {
          if (message === ERROR_MESSAGES.CODE_HAS_BEEN_SENT) {
            actions.setErrors({
              phone: t('profile.tooManyRequest'),
            });
          }
        }
        if (status === ERROR_STATUSES.INVALID_RECAPTCHA) {
          setIsRecaptchaError(true);
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (
        stateSubmitingUserPhone?.data?.status === 'OK' &&
        countryCode === COUNTRY_CODES.BY
      ) {
        setIsPhoneConfirmModal(true);
        actions.setSubmitting(false);
      } else if (
        stateSubmitingUserPhone?.data &&
        countryCode !== COUNTRY_CODES.BY
      ) {
        actions.setSubmitting(false);
        onSuccessCallback(stateSubmitingUserPhone.data);
      }
    }
  }, [stateSubmitingUserPhone]);

  useEffect(() => {
    if (user.data) {
      user.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.loaded]);

  useEffect(() => {
    if (
      formikRef.current &&
      agreements.isRuleAgree &&
      agreements.isPromoAgree
    ) {
      setAgreements(prev => ({ ...prev, isMainAgree: true }));
      formikRef.current.setFieldValue('mainAgreement', true);
    }
  }, [agreements.isRuleAgree, agreements.isPromoAgree, formikRef]);

  const onSubmitHandler = useCallback(
    async (values, actions) => {
      setCountryCode(values.countryCode);
      const requestData = {
        email: values.registrationEmail,
        countryCode: values.countryCode,
        phone: values.phone,
      };
      const result = await executeRecaptcha('signUp');
      stateSubmitingUserPhone.setFormAndSubmit({
        headers: {
          recaptcha: result,
        },
        values: requestData,
        actions,
      });
      setData(values);
      setVerificationActions(actions);
    },
    [executeRecaptcha]
  );

  const handleAttachCode = async (values, actions) => {
    try {
      const result = await executeRecaptcha('signUp');
      const response = await axios.post(setRegistrationPhoneVerify, {
        headers: {
          recaptcha: result,
        },
        code: Object.values(values).join(''),
      });
      if (response.status === 200) {
        onSuccessCallback();
      }
    } catch (error) {
      if (error.response.data?.status === ERROR_STATUSES.BAD_REQUEST) {
        actions.resetForm();
        document.getElementById('otc-1').focus();
        setTimeout(
          () =>
            actions.setErrors({
              code: t('passwordForms.errors.invalidCode'),
            }),
          0
        ); // timeout because focus reset error
      } else {
        // skip the phone number verification error in case of failures in the SMS sending service
        setIsPhoneVerifyError(true);
      }
    }
  };

  useEffect(() => {
    if (isPhoneVerifyError) {
      onSuccessCallback();
    }
  }, [isPhoneVerifyError]);

  const handleCheckEmailStatus = async values => {
    try {
      const response = await axios.get(
        getEmailStatus({ email: values.registrationEmail })
      );
      if (response) {
        if (!response?.data) {
          setIsEmailError(true);
          formikRef.current.setFieldError(
            'registrationEmail',
            t('signUpForm.errors.userExists')
          );
        } else {
          setIsEmailError(false);
        }
      }
    } catch (error) {
      if (error.response.data.status === ERROR_STATUSES.INVALID_EMAIL) {
        setIsEmailError(true);
        formikRef.current.setFieldError(
          'registrationEmail',
          t('signUpForm.errors.incorrectEmailFormat')
        );
      }
    }
  };

  return !isPhoneConfirmModal ? (
    <>
      {isRecaptchaError ? (
        <ErrorModal isReloadButton />
      ) : (
        <>
          <Header>{t('signUpForm.registerLabel')}</Header>
          {partnerName && (
            <InfoPartner>
              {t('signUpForm.partnerText')} {partnerName}
            </InfoPartner>
          )}
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={signUpSchema(t)}
            onSubmit={onSubmitHandler}
            validateOnChange
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
              setFieldValue,
            }) => (
              <FormGrid onSubmit={handleSubmit}>
                <FormGroup>
                  <Field
                    id="registrationEmail"
                    name="registrationEmail"
                    type="email"
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e);
                      handleCheckEmailStatus(values);
                    }}
                    value={values.registrationEmail}
                    component={Input}
                    placeholder="yourmail@mail.com "
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    isFieldError={isEmailError}
                  />

                  {isEmailError ? (
                    <Error>
                      {errors.registrationEmail ||
                        t('signUpForm.errors.userExists')}
                    </Error>
                  ) : (
                    errors.registrationEmail &&
                    touched.registrationEmail && (
                      <Error>{errors.registrationEmail}</Error>
                    )
                  )}
                </FormGroup>
                <FormGroup>
                  <WrapEye>
                    {isShowPassword ? (
                      <OpenEyeIcon onClick={() => setShowPassword(false)} />
                    ) : (
                      <CloseEyeIcon onClick={() => setShowPassword(true)} />
                    )}
                  </WrapEye>

                  <Field
                    id="password"
                    type={isShowPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e);
                      if (values.registrationEmail.length > 0) {
                        handleCheckEmailStatus(values);
                      }
                    }}
                    value={values.password}
                    component={Input}
                    placeholder={t('signUpForm.passPlaceholder')}
                    label={t('signUpForm.passLabel')}
                    autoComplete="new-password"
                    capsLock
                    inputHiddenText
                  />
                  {errors.password && touched.password && (
                    <Error password>{errors.password}</Error>
                  )}

                  <ReactIsCapsLockActive>
                    {active =>
                      active && (
                        <Error icon password>
                          {t('signUpForm.errors.capsLockEnabled')}
                        </Error>
                      )
                    }
                  </ReactIsCapsLockActive>
                </FormGroup>
                <FormGroup>
                  <Field
                    id="countryCode"
                    type="text"
                    name="countryCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={CountryCodeSelect}
                    t={t}
                    isRegistration
                  />
                  <Field
                    id="phone"
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    resetInput={() => {}}
                    component={PhoneInput}
                    placeholder={getInputMask(values.countryCode)}
                    label={t('profile.inputLabels.phone')}
                    autoComplete="off"
                    isRegistration
                  />
                  {errors?.phone && touched?.phone && (
                    <Error top={50}>{errors.phone}</Error>
                  )}
                </FormGroup>
                <FormGroup>
                  <Field
                    onClick={e => {
                      setAgreements({
                        isMainAgree: e.target.checked,
                        isRuleAgree: e.target.checked,
                        isPromoAgree: e.target.checked,
                      });
                      setFieldValue('agreements', e.target.checked);
                      setFieldValue('promoAgreement', e.target.checked);
                    }}
                    setFieldValue={setFieldValue}
                    id="mainAgreement"
                    name="mainAgreement"
                    value={agreements.isMainAgree}
                    component={CustomCheckbox}
                    type="checkbox"
                    alignItems="center"
                    isMain
                    label={
                      <Agreements>
                        <Trans i18nKey="signUpForm.agreementMain" t={t}>
                          Согласие с условиями регистрации
                        </Trans>
                      </Agreements>
                    }
                  />
                  {status?.mainAgreement ? (
                    <Error> {status.mainAgreement}</Error>
                  ) : (
                    errors.mainAgreement &&
                    touched.mainAgreement &&
                    !agreements.isRuleAgree && (
                      <Error>{errors.mainAgreement}</Error>
                    )
                  )}
                </FormGroup>
                <FormGroup>
                  <Field
                    onClick={e => {
                      setAgreements(prev => ({
                        ...prev,
                        isMainAgree: agreements.isRuleAgree
                          ? e.target.checked
                          : !e.target.checked,
                        isRuleAgree: e.target.checked,
                      }));
                      setFieldValue(
                        'mainAgreement',
                        agreements.isRuleAgree
                          ? e.target.checked
                          : !e.target.checked
                      );
                    }}
                    setFieldValue={setFieldValue}
                    id="agreements"
                    name="agreements"
                    value={agreements.isRuleAgree}
                    component={CustomCheckbox}
                    type="checkbox"
                    alignItems="initial"
                    label={
                      <Agreements>
                        <Trans i18nKey="signUpForm.agreement1" t={t}>
                          Регистрируясь в информационной системе Whitebird и
                          создавая электронный личный кабинет, Вы соглашаетесь с
                          условиями
                          <a
                            href="/assets/files/public-offer_07_2024.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Публичной оферты
                          </a>
                          (включая её неотъемлемые части: Общие условия
                          реализации токенов, Правила обращения с персональными
                          данными клиентов, Положение о поддержке пользователей)
                        </Trans>
                      </Agreements>
                    }
                  />
                  {status?.agreements ? (
                    <Error> {status.agreements}</Error>
                  ) : (
                    errors.agreements &&
                    touched.agreements &&
                    !agreements.isPromoAgree && (
                      <Error>{errors.agreements}</Error>
                    )
                  )}
                </FormGroup>
                <FormGroup>
                  <Field
                    onClick={e => {
                      setAgreements(prev => ({
                        ...prev,
                        isPromoAgree: e.target.checked,
                      }));
                    }}
                    setFieldValue={setFieldValue}
                    id="promoAgreement"
                    name="promoAgreement"
                    value={agreements.isPromoAgree}
                    component={CustomCheckbox}
                    type="checkbox"
                    alignItems="initial"
                    label={
                      <Agreements>
                        <Trans i18nKey="signUpForm.agreement2" t={t}>
                          Согласен(на) на получение информационных и рекламных
                          сообщений от Whitebird.
                        </Trans>
                      </Agreements>
                    }
                  />
                  {status?.promoAgreement ? (
                    <Error> {status.promoAgreement}</Error>
                  ) : (
                    errors.promoAgreement &&
                    touched.promoAgreement && (
                      <Error>{errors.promoAgreement}</Error>
                    )
                  )}
                </FormGroup>
                {process.env?.REACT_APP_RECAPTCHA_KEY && (
                  <GoogleReCaptcha
                    action="signUp"
                    onVerify={handleReCaptchaVerify}
                  />
                )}
                <ButtonWrapper>
                  <Button
                    type="submit"
                    disabled={
                      !agreements.isRuleAgree ||
                      isSubmitting ||
                      !isEmpty(errors) ||
                      isEmailError
                    }
                  >
                    {t('signUpForm.createAccount')}
                  </Button>
                </ButtonWrapper>
              </FormGrid>
            )}
          </Formik>
          <Info>
            {t('signUpForm.alreadyHaveAccount')}{' '}
            <Link to={SIGN_IN}>
              <Adding>{t('signUpForm.accountLogin')}</Adding>
            </Link>
          </Info>
        </>
      )}
    </>
  ) : (
    <ConfirmationPhone
      t={t}
      initialValues={initialValues}
      handleSubmit={handleAttachCode}
    />
  );
};

export default SignUp;
