import styled from 'styled-components';
import device from 'device';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  @media ${device.tablet} {
    display: none;
  }
`;

export const TH = styled.th`
  padding: 0 0 15px 0;
  margin: 0;
  border-bottom: 1px solid #dedede;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.fullBlackText};
  text-align: left;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 308px;
  }
`;

export const TBody = styled.tbody`
  & tr {
    border-bottom: 1px solid #dedede;
  }
`;

export const TD = styled.td`
  text-align: left;
  height: 64px;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  &:first-child {
    display: flex;
    align-items: center;
  }
`;

export const CurrencyRowContent = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  margin-right: 40px;

  @media ${device.laptop} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    margin-right: 20px;
    width: 80px;
  }
`;

export const Network = styled.div`
  font-size: 13px;
  line-height: 16px;
  width: 120px;
  letter-spacing: -0.01em;
  margin-left: 8px;
  color: #a8a8a8;
`;

export const HistoryLink = styled.span`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.btnColor};
  padding-bottom: 2px;
  border-bottom: 1px dashed ${props => props.theme.linkColor};
  cursor: pointer;
`;

export const Total = styled(TD)`
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: ${props => props.theme.fullBlackText};

  @media ${device.mobile920} {
    font-size: 16px;
  }
`;

export const OperationProgress = styled(TD)`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #ff7a00;
  text-align: left;
`;

export const Button = styled.button`
  padding: 8px 16px;
  background: ${props => props.theme.blueBg};
  border-radius: 8px;
  font-size: 15px;
  line-height: 18px;
  color: ${props => props.theme.fullWhite};
  margin-right: 14px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }

  @media ${device.tablet} {
    padding: 6px 14px;
    margin-right: 12px;
    font-size: 14px;
  }
`;

export const StyledTableMobile = styled.div`
  width: 100%;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const TableMobileHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #dedede;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.fullBlackText};
`;

export const TableMobileBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 25px;
  border-bottom: 1px solid #dedede;
`;

export const MobileBodyContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalMobile = styled.div`
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: ${props => props.theme.fullBlackText};
  margin-left: auto;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const OperationProgressMobile = styled.div`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #ff7a00;
  text-align: left;
  margin-top: 20px;
`;

export const ButtonBlockMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
