/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useRef, useState, useEffect } from 'react';
import { CustomInputWrapper, Textarea } from 'components/Exchanger/styled-ui';
import { AddressFullView, ThreeDotInputValue } from './styled-ui';

const CustomInput = ({
  name,
  value,
  setFieldValue,
  isError,
  placeholder,
  disabled,
  isWalletTypes,
  isExtType,
}) => {
  const targetRef = useRef();
  const addressInput = useRef();
  const [isInputTextHidden, setIsInputTextHidden] = useState(false);
  const [imagineValue, setImagineValue] = useState('');

  const convertAddressValue = (isTextWiderInput, countOfPartSymbols) => {
    if (!isTextWiderInput) {
      setImagineValue('');
      return;
    }
    setImagineValue(
      `${value.slice(0, countOfPartSymbols)}…${value.slice(
        -1 * countOfPartSymbols
      )}`
    );
  };

  const getCorrectInputValue = () => {
    const exactInputWidth = targetRef.current.parentElement.offsetWidth - 20; // 20px padding
    const inputTextWidth = targetRef.current.clientWidth;
    const isTextWiderInput = inputTextWidth > exactInputWidth;

    const countOfPartSymbols = Math.floor(
      ((value.length * exactInputWidth) / inputTextWidth - 2) / 2 // -2 to be sure that letters will visible, /2 to split left and right parts
    );

    if (isTextWiderInput) {
      setIsInputTextHidden(true);
    } else {
      setIsInputTextHidden(false);
    }
    convertAddressValue(isTextWiderInput, countOfPartSymbols);
  };

  const onChange = event => {
    const {
      target: { value: eValue },
    } = event;
    const formattedValue = eValue.replace(/\s/g, '');
    setFieldValue(name, formattedValue);
  };

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getViewValue = () => {
    const isInputFocused = document.activeElement === addressInput.current;
    if (isInputFocused) return value;
    return imagineValue || value;
  };

  const resetImagineValue = () => setImagineValue('');

  useEffect(() => {
    if (!value) {
      resetImagineValue();
    }
  }, [value]);

  return (
    <CustomInputWrapper
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleMouseOver}
      isWalletTypes={isWalletTypes}
    >
      <Textarea
        ref={addressInput}
        name={name}
        value={isExtType ? getViewValue() : ''}
        onFocus={resetImagineValue}
        onBlur={getCorrectInputValue}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        spellCheck="false"
      />
      {/* <ConvertedAddressWrapper>
        <StartAddress>{value}</StartAddress>
        <EndAddress>{value}</EndAddress>
      </ConvertedAddressWrapper> */}
      <ThreeDotInputValue ref={targetRef}>{value}</ThreeDotInputValue>
      <AddressFullView
        isVisible={isHovering && value && isInputTextHidden}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleMouseOver}
        onBlur={handleMouseOut}
      >
        {value}
      </AddressFullView>
    </CustomInputWrapper>
  );
};

CustomInput.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default CustomInput;
