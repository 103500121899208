/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { getDepositOrders } from 'utils/services/request/operations';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import ClientContext from 'utils/contexts/Client';
import { fromLocaleNumber } from 'utils/number';
import { isCrypto } from 'utils/crypto';
import requestErrors from 'utils/constants/requestErrors';
import { REPLENISHMENT } from 'utils/constants/exchangeTabs';
import validationFieldsValue from 'components/Replenishment/validation';
import ReplenishmentCalculator from 'components/Replenishment/ReplenishmentCalculator';

const Replenishment = ({ refresh, isOrderUpdated }) => {
  const { t } = useTranslation();
  const client = useContext(ClientContext);
  const clientStatus = pathOr('', ['data', 'status'], client);

  const setCurrency = (setValues, setTouched, values) => (name, newValue) => {
    const currency = name.includes('currency') && newValue;

    if (isCrypto(localStorage.getItem('from_currency'))) {
      localStorage.setItem('from_currency', currency);
    } else {
      localStorage.setItem('to_currency', currency);
    }

    setValues({
      ...values,
      calculation: {
        inputAsset: values.calculation.inputAsset,
      },
      currency: newValue,
    });
  };

  const submitReplenishmentForm = useAxiosSubmitingEffect(getDepositOrders);

  const onSubmitHandler = (values, actions) => {
    const { calculation: value, currency } = values;

    const requestData = {
      calculation: {
        inputAsset: fromLocaleNumber(value.inputAsset),
      },
      currency,
    };
    submitReplenishmentForm.setFormAndSubmit({
      values: requestData,
      actions,
    });
    localStorage.setItem('tab', REPLENISHMENT);
  };

  useEffect(() => {
    if (submitReplenishmentForm.loaded) {
      if (isOrderUpdated) {
        submitReplenishmentForm.form.actions.setSubmitting(false);
      }
      const { actions } = submitReplenishmentForm.form;
      if (submitReplenishmentForm.error) {
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        if (submitReplenishmentForm.error?.status === 'NOT_FOUND') {
          refresh();
        }
        if (
          Object.keys(requestErrors).includes(
            submitReplenishmentForm.error?.status
          )
        ) {
          actions.setErrors({
            orderCreation: {
              message: t(
                `calculationView.errors.responseErrors.${submitReplenishmentForm.error.status}`,
                { operation: 'пополнения' }
              ),
            },
          });
        }
      } else {
        refresh();
      }
    }
    if (!submitReplenishmentForm.error && submitReplenishmentForm.form) {
      const { actions } = submitReplenishmentForm.form;
      actions.setErrors({});
    }
  }, [
    refresh,
    submitReplenishmentForm,
    submitReplenishmentForm.loaded,
    submitReplenishmentForm.error,
    isOrderUpdated,
    t,
  ]);

  const currency = isCrypto(localStorage.getItem('from_currency'))
    ? localStorage.getItem('from_currency')
    : isCrypto(localStorage.getItem('to_currency'))
    ? localStorage.getItem('to_currency')
    : 'BTC';

  const initialValues = {
    calculation: {
      inputAsset: isCrypto(localStorage.getItem('from_currency'))
        ? localStorage.getItem('input_asset')
        : 0,
    },
    currency,
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      validate={validationFieldsValue(clientStatus, t)}
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        touched,
        setValues,
        setTouched,
        validateForm,
        errors,
        ...props
      }) => (
        <ReplenishmentCalculator
          values={values}
          setValues={setValues}
          touched={touched}
          errors={errors}
          validateForm={validateForm}
          {...props}
          setCurrency={setCurrency(setValues, setTouched, values, touched)}
        />
      )}
    </Formik>
  );
};

export default Replenishment;
