import PropTypes from 'prop-types';
import React from 'react';
import { ExtraSmallButton } from 'ui-kit/Button/index';
import { Switcher } from './styled-ui';

const ExchangeTypeSwitcher = ({ t, value, setPageView, exchangeTabs }) => (
  <Switcher>
    {exchangeTabs.map(type => (
      <ExtraSmallButton
        isMedia
        key={type}
        name="mediaType"
        onClick={() => setPageView(type)}
        type="button"
        active={value === type}
      >
        {t(`exchangeTabs.${type}`)}
      </ExtraSmallButton>
    ))}
  </Switcher>
);

ExchangeTypeSwitcher.propTypes = {
  setPageView: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ExchangeTypeSwitcher;
