import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getClearPathname } from 'utils/location';
import {
  BELARUSBANK,
  DISPUTE,
  FAQ,
  HOME,
  SUPPORT,
  MEDIA,
  USER_PROFILE,
  INFORMATION,
} from 'constants/routings';
import Footer from '../Footer';
import Header from '../Header';
import {
  DashboardContainer,
  Page,
  WrapContent,
  WrapHeader,
  Wrapper,
  Description,
} from './styled-ui';

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const items = [
    { to: HOME, label: t('pageTitle.main') },
    { to: FAQ, label: t('pageTitle.faq') },
    { to: MEDIA, label: t('pageTitle.media') },
    { to: SUPPORT, label: t('pageTitle.support') },
    {
      to: BELARUSBANK,
      label: t('pageTitle.belarusbank'),
    },
    { to: DISPUTE, label: t('pageTitle.dispute') },
    { to: USER_PROFILE, label: t('pageTitle.userProfile') },
    { to: INFORMATION, label: t('pageTitle.information') },
  ];
  const title = items
    .filter(pathname => {
      return pathname.to === getClearPathname(location);
    })
    .shift();

  return (
    <>
      <DashboardContainer path={location.pathname}>
        <WrapHeader path={location.pathname}>
          <Header />
          <Wrapper>
            {title.label && <Page>{title.label}</Page>}
            {title.description && (
              <Description>{title.description}</Description>
            )}
          </Wrapper>
        </WrapHeader>
        <WrapContent>{children}</WrapContent>
      </DashboardContainer>
      <Footer />
    </>
  );
};

export default DashboardLayout;
