import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME, VERIFICATIONS } from 'constants/routings';
import Steps from 'ui-kit/Steps';
import ClientContext from 'utils/contexts/Client';
import UserContext from 'utils/contexts/User';
import { getClientTestingNeeded } from 'utils/user';
import { userStatuses } from 'utils/constants/statutes';
import { REGULA_MODES } from 'utils/constants/regula';
import FaceLiveness from './FaceLiveness';
import { ContentWrapper, LoaderWrapper, RegulaWrapper } from './styled-ui';
import ConfirmForm from './ConfirmForm';
import TestForm from './TestForm';
import Loader from '../Loader';
import CameraSnapshot from './CameraSnapshot';

const Regula = ({ operatingMode }) => {
  const { t } = useTranslation();
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [livenessResult, setLivenessResult] = useState('');
  const isClientTestingRequired = getClientTestingNeeded(client);
  const isChangeDocuments = [
    REGULA_MODES.changeDocuments,
    REGULA_MODES.changeRegistration,
  ].includes(operatingMode);

  useEffect(() => {
    if (client.userConfirmed) {
      user.refetch();
      window.location.href = HOME;
    }
    let page;
    const data = client?.data;
    if (data) {
      if (data.status === userStatuses.NOT_VERIFIED && isChangeDocuments) {
        window.location.href = VERIFICATIONS;
      } else if (
        data.status !== userStatuses.NOT_VERIFIED &&
        isChangeDocuments
      ) {
        page = 2;
      } else if (data.status === userStatuses.NOT_VERIFIED) {
        page = 1;
      } else if (data.status === userStatuses.PENDING) {
        page = 4;
      } else if (
        data.status === userStatuses.VERIFIED &&
        isClientTestingRequired
      ) {
        page = 4;
      } else {
        setRedirect(true);
      }
    }
    setTimeout(() => {
      setCurrentPage(page);
      setIsLoader(false);
    }, 2000);
  }, [user, client, isChangeDocuments, isClientTestingRequired]);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const items = [
    {
      title: t('verification.steps.confirm.title'),
      description: t('verification.steps.confirm.description'),
      content: <ConfirmForm callbackProps={{ nextPage }} />,
    },
    {
      title: t('verification.steps.liveness.title'),
      description: t('verification.steps.liveness.description'),
      content: (
        <FaceLiveness
          callbackProps={{ nextPage, setLivenessResult }}
          operatingMode={operatingMode}
        />
      ),
    },
    {
      title: t('verification.steps.documents.title'),
      description: t('verification.steps.documents.description'),
      content: (
        <CameraSnapshot
          callbackProps={{ nextPage, livenessResult, setIsLoader }}
          operatingMode={operatingMode}
        />
      ),
    },
    {
      title: t('verification.steps.test.title'),
      description: t('verification.steps.test.description'),
      content: <TestForm callbackProps={{ setIsLoader }} />,
    },
  ];

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      <RegulaWrapper>
        {currentPage && (
          <>
            <Steps currentPage={currentPage} items={items} />
            <ContentWrapper>
              {currentPage && items[currentPage - 1].content}
            </ContentWrapper>
          </>
        )}
        {isLoader && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </RegulaWrapper>
    </>
  );
};

export default Regula;
