import React, { useContext } from 'react';
import moment from 'moment';
import CurrenciesContext from 'utils/contexts/Currencies';
import useMobileSize from 'utils/hooks/useMobileSize';
import { getNetworkByCurrency } from 'utils/crypto';
import { numberWithSpaces } from 'utils/number';
import { CRYPTO_CURRENCIES } from 'utils/constant';
import Pagination from '../../Pagination';

import {
  StyledTable,
  THead,
  TBody,
  TH,
  TD,
  Title,
  Currency,
  Network,
  Status,
  Circle,
  HistoryNotFound,
} from './styled-ui';

const STATUSES = {
  PROCESSED: 'PROCESSED',
  DECLINED: 'DECLINED,',
};

const WalletHistory = ({
  t,
  history,
  pagination,
  onPageChange,
  currencyValue,
}) => {
  const isMobile = useMobileSize();
  const currencies = useContext(CurrenciesContext);
  const network =
    currencyValue !== CRYPTO_CURRENCIES.USDT &&
    getNetworkByCurrency(currencies?.data?.cryptoCurrencies, currencyValue);

  return (
    <>
      <Title>
        {t('profile.wbWalletTab.historyTable.title', {
          value: currencyValue,
        })}
        {network && <span>{network}</span>}
      </Title>
      <StyledTable isHistoryEmpty={history.length === 0}>
        <THead>
          <tr>
            {isMobile ? (
              <>
                <TH>{t('profile.wbWalletTab.historyTable.typeOperation')}</TH>
                <TH>{t('profile.wbWalletTab.historyTable.status')}</TH>
                <TH>{t('profile.wbWalletTab.historyTable.changedAmount')}</TH>
              </>
            ) : (
              <>
                <TH>{t('profile.wbWalletTab.historyTable.dateOperation')}</TH>
                <TH>{t('profile.wbWalletTab.historyTable.typeOperation')}</TH>
                <TH>{t('profile.wbWalletTab.historyTable.currency')}</TH>
                <TH>{t('profile.wbWalletTab.historyTable.status')}</TH>
                <TH textAlign="right">
                  {t('profile.wbWalletTab.historyTable.withdrawalAmount')}
                </TH>
                <TH textAlign="right">
                  {t('profile.wbWalletTab.historyTable.commissionAmount')}
                </TH>
                <TH>{t('profile.wbWalletTab.historyTable.changedAmount')}</TH>
              </>
            )}
          </tr>
        </THead>
        {history.length > 0 ? (
          <TBody>
            {history.map(value => (
              <tr key={value.creationDate}>
                {isMobile ? (
                  <>
                    <TD>
                      <div>
                        {value.changedAmount > 0
                          ? t('profile.wbWalletTab.historyTable.replenishment')
                          : t('profile.wbWalletTab.historyTable.writeOff')}
                      </div>
                      <div>
                        {moment(value.creationDate).format('DD.MM.YYYY, HH:mm')}
                      </div>
                    </TD>
                    <Status>
                      <Circle success={value.status === STATUSES.PROCESSED} />
                      <div>
                        {value.status === STATUSES.PROCESSED
                          ? t('profile.wbWalletTab.historyTable.success')
                          : t('profile.wbWalletTab.historyTable.cancel')}
                      </div>
                    </Status>
                    <TD>
                      {numberWithSpaces(
                        value.changedAmount > 0
                          ? `+${value.changedAmount}`
                          : value.changedAmount
                      )}
                    </TD>
                  </>
                ) : (
                  <>
                    <TD>
                      {moment(value.creationDate).format('DD.MM.YYYY, HH:mm')}
                    </TD>
                    <TD>
                      {value.changedAmount > 0
                        ? t('profile.wbWalletTab.historyTable.replenishment')
                        : t('profile.wbWalletTab.historyTable.writeOff')}
                    </TD>
                    <TD>
                      <Currency>{value.currency}</Currency>
                      {network && <Network>{network}</Network>}
                    </TD>
                    <Status>
                      <Circle success={value.status === STATUSES.PROCESSED} />
                      <div>
                        {value.status === STATUSES.PROCESSED
                          ? t('profile.wbWalletTab.historyTable.success')
                          : t('profile.wbWalletTab.historyTable.cancel')}
                      </div>
                    </Status>
                    <TD textAlign="right">{value.withdrawalAmount || '-'}</TD>
                    <TD textAlign="right">{value.commissionAmount || '-'}</TD>
                    <TD>
                      {numberWithSpaces(
                        value.changedAmount > 0
                          ? `+${value.changedAmount}`
                          : value.changedAmount
                      )}
                    </TD>
                  </>
                )}
              </tr>
            ))}
          </TBody>
        ) : (
          <TBody>
            <tr>
              <HistoryNotFound>
                {t('profile.wbWalletTab.historyTable.notFound')}
              </HistoryNotFound>
            </tr>
          </TBody>
        )}
      </StyledTable>
      <Pagination
        totalPages={pagination?.totalPages}
        totalRecords={pagination?.totalElements}
        onPageChanged={onPageChange}
      />
    </>
  );
};

export default WalletHistory;
