import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import UserProfile from 'components/Auth/UserProfile';

const UserProfileWrapper = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();

  if (!user.data) {
    history.push(HOME);
  }

  return <UserProfile t={t} />;
};

export default UserProfileWrapper;
