import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import useTimer from 'utils/hooks/timer';
import { currencies } from 'utils/constant';
import { isCrypto } from 'utils/crypto';
import { Timer, TimerTime } from './styled-ui';

const TimerStatus = ({
  currencyPair,
  operationDate,
  setDisabled,
  t,
  refresh,
  isChangeEmailModal,
  isReplenishment,
  isExchangeExtCryptoBuy,
  isEripOrder,
}) => {
  const isTypeOperation =
    (isCrypto(currencyPair?.fromCurrency) && isExchangeExtCryptoBuy) ||
    isReplenishment ||
    isEripOrder;
  const setMinutes = isTypeOperation ? 30 : 3;
  const [showTimer, setShowTimer] = useState(true);
  const operationDateTimer = DateTime.fromISO(operationDate).plus({
    minutes: setMinutes,
  });

  const { seconds, minutes } = useTimer({
    expiryTimestamp: operationDateTimer.toMillis() + 3000,
    onExpire: () => {
      setDisabled(true);
      setShowTimer(false);
      if (!isChangeEmailModal) {
        refresh();
      }
    },
  });

  if (seconds <= 0 && minutes <= 0) {
    return null;
  }

  return (
    <Timer>
      {showTimer && (
        <>
          {isChangeEmailModal
            ? t('profile.modalLabels.cancelAfter')
            : t('pending.cancelAfter')}
          <TimerTime>
            {minutes < 10 ? ` 0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </TimerTime>
        </>
      )}
    </Timer>
  );
};

TimerStatus.propTypes = {
  operationDate: PropTypes.string.isRequired,
  setDisabled: PropTypes.func.isRequired,
  currencyPair: PropTypes.shape({
    fromCurrency: PropTypes.oneOf(currencies).isRequired,
    toCurrency: PropTypes.oneOf(currencies).isRequired,
  }),
};

TimerStatus.defaultProps = {
  currencyPair: null,
};

export default TimerStatus;
