import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import device from 'device';
import { ButtonLinkTransparentOnHeader } from 'ui-kit/Button';
import { ReactComponent as Ring } from 'assets/images/icons/ring.svg';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';
import { ReactComponent as Swap } from 'assets/images/icons/swap.svg';

export const Information = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    position: initial;
  }
`;

export const UserInformation = styled.div`
  cursor: pointer;
  margin-left: 20px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
`;

export const UserVerificationText = styled.span`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.fullWhite};
`;

export const UserInformationOverlay = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 999999999;
  @media ${device.mobileHeader} {
    right: -8px;
    top: 65px;
  }
  @media (max-width: 480px) {
    right: 0;
    top: 80px;
    margin: 0 15px;
  }
`;

export const UserInformationDetails = styled.div`
  height: auto;
  width: ${({ isInfo }) => (isInfo ? '445px' : '388px')};
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  padding: 0 0 20px 0;
  padding-top: ${({ isVerified }) => isVerified && '20px'};
  margin-top: 8px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 15px;
  right: -8px;
  top: 80px;
  @media (max-width: 480px) {
    width: unset;
  }
`;

export const UserExit = styled.div`
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
  display: flex;
  align-items: center;
`;

export const UserLabelValue = styled.div`
  position: relative;
  color: ${({ isInfo, theme }) => (isInfo ? theme.errorColor : '#000')};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  @media ${device.mobileHeader} {
    font-size: 14px;
  }
`;

export const UserLabelValueLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;

export const UserIconWrap = styled.div`
  display: flex;
  svg {
    padding-right: 10px;
    padding-left: 10px;
    width: 20px;
    height: 20px;
  }
`;

export const UserExitButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Inter';
  padding: 0;
  color: #323233;
  svg {
    width: 20px;
    height: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  @media ${device.mobileHeader} {
    font-size: 14px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #323233;
  font-size: 16px;
  line-height: 19px;
  padding: 0 10px;
`;

export const SignInLink = styled(ButtonLinkTransparentOnHeader)`
  align-self: center;
  margin: ${({ margin }) => margin};
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  position: relative;
  @media ${device.mobileHeader} {
    position: initial;
  }
`;

export const ProfileIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ProfileText = styled.div`
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
`;

export const RingIcon = styled(({ isActive, IsBellAnimation, ...props }) => (
  <Ring {...props} />
))`
  display: flex;
  justify-self: end;
  position: absolute;
  left: 6.5px;
  width: 22px;
  height: 22px;
  animation: ${({ IsBellAnimation }) =>
    IsBellAnimation ? 'bell 1s 1s both infinite' : 'none'};

  @keyframes bell {
    0% {
      transform: rotate(0);
    }
    10% {
      transform: rotate(30deg);
    }
    20% {
      transform: rotate(0);
    }
    80% {
      transform: rotate(0);
    }
    90% {
      transform: rotate(-30deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  path {
    fill: ${({ isActive, theme }) => (isActive ? theme.fullWhite : '#C3DCFF')};
  }
  path:last-child {
    stroke: ${({ isActive, theme }) =>
      isActive ? theme.fullWhite : '#C3DCFF'};
  }
`;

export const CircleIconBg = styled.div`
  position: absolute;
  left: -2.5px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  transform: ${({ isActive }) => (isActive ? 'scale(1)' : 'scale(0)')};
  transition: all 450ms ease 0ms;
  background-color: ${({ isActive }) => isActive && '#2a82ff'};
`;

export const UnreadNotificationDot = styled.div`
  position: absolute;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.notificatonErrorColor};
  top: -2px;
  right: -6px;
  border: 2px solid
    ${({ isActive, theme }) => (isActive ? '#2a82ff' : theme.blueBg)};
  @media ${device.mobileHeader} {
    top: 14px;
  }
`;

export const NotificationCount = styled.span`
  font-size: 12px;
  color: ${props => props.theme.fullWhite};
`;

export const RingButton = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 0;
  width: 35px;
  margin-left: 15px;
  &:after {
    display: ${({ isActive }) => !isActive && 'none'};
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: ${({ theme }) => `9px solid ${theme.fullWhite}`};
    left: 5px;
    top: 45px;
    z-index: 99999999999;
    @media ${device.mobileHeader} {
      top: 63px;
    }
  }
  align-items: center;
  &:hover > ${CircleIconBg} {
    background: #2a82ff;
    border-radius: 50%;
    transform: scale(1);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  &:hover > ${UnreadNotificationDot} {
    border: 2px solid #2a82ff;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  &:hover > ${RingIcon} {
    path {
      fill: ${({ theme }) => theme.fullWhite};
    }
    path:last-child {
      stroke: ${({ theme }) => theme.fullWhite};
    }
  }
`;

export const StatusAccount = styled.span`
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
  letter-spacing: 0.2px;
  background: ${({ status }) => {
    switch (status) {
      case 'NOT_VERIFIED':
        return '#ff4f6e;';
      case 'PENDING':
        return '#ffbf1c;';
      case 'FROZEN':
        return '#ff4f6e;';
      default:
        return '#fff;';
    }
  }};
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const InfoIcon = styled(Info)`
  circle {
    fill: ${({ theme }) => theme.errorColor};
  }
  path {
    fill: ${({ theme }) => theme.errorColor};
  }
`;

export const ExchangeLink = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 24px;
  background: #fff;
  color: ${props => props.theme.blackText};
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
  margin: 0 0 0 15px;
  border-radius: 8px;
`;

export const SwapIcon = styled(Swap)`
  width: 15px;
  height: 20px;
  margin-right: 10px;
  path {
    stroke: #0069ff;
  }
`;
