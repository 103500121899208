import React from 'react';
import CommissionInfo from 'components/CommissionInfo/styled-ui';
import discountTypes from 'utils/constants/promocode';

const OrderPromoInfo = ({ t, code, discount, currency, type }) => {
  const value = `${discount}${currency ? ` ${currency}` : '%'}`;
  return (
    <CommissionInfo>
      {type === discountTypes.COMMISSION_PERCENT
        ? t('promocode.orderServiceCommissionPromo', { value, code })
        : t('promocode.orderPromoInfo', { value, code })}
    </CommissionInfo>
  );
};

export default OrderPromoInfo;
