import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GoogleReCaptchaWrapper = Component => {
  const reCaptchaKey = process.env?.REACT_APP_RECAPTCHA_KEY;
  return reCaptchaKey ? (
    <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={reCaptchaKey}>
      <Component />
    </GoogleReCaptchaProvider>
  ) : (
    <Component />
  );
};

export default GoogleReCaptchaWrapper;
