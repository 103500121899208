import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, pathOr } from 'ramda';
import { Form } from 'formik';
import CurrenciesContext from 'utils/contexts/Currencies';
import ClientContext from 'utils/contexts/Client';
import { getCryptoCalculation } from 'utils/services/request/operations';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { fromLocaleNumber } from 'utils/number';
import { getClientTestingNeeded } from 'utils/user';
import { WALLETS } from 'utils/constants/profileTabs';
import requestErrors from 'utils/constants/requestErrors';
import DropdownInput from 'ui-kit/DropdownInput';
import { getCurrencyLabel } from 'components/Exchanger/ExchangerCalculator';
import LimitRateError from 'components/Exchanger/LimitRateError';
import SubmitButton from 'components/Exchanger/SubmitButton';
import LimitRate from 'components/Exchanger/LimitRate';

import {
  ExchangerWrapper,
  ReplenishmentHeader,
  WbWalletBtn,
  ReplenishmentWrapper,
  ReplenishmentContainer,
  SystemInfoError,
  LimitContent,
  Container,
} from '../Exchanger/styled-ui';

const DEBOUNCE_TIMER = 400;

const ReplenishmentCalculator = ({
  values: {
    calculation: { inputAsset },
    currency,
  },
  setCurrency,
  isSubmitting,
  errors,
  validateForm,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currencies = useContext(CurrenciesContext);
  const client = useContext(ClientContext);
  const [calculation, setCalculation] = useState(null);
  const [inputData, setInputData] = useState({
    currency,
    inputAsset,
  });
  const isNotVerified =
    !isNil(client.data) && client?.data?.status !== 'VERIFIED';
  const isShowLimitRate =
    typeof pathOr('', ['limit', 'min'], calculation) === 'number' &&
    typeof pathOr('', ['limit', 'max'], calculation) === 'number';
  const isTestingRequired = getClientTestingNeeded(client);

  const calculationInfo = useAxiosState(
    getCryptoCalculation(
      inputData.inputAsset ? fromLocaleNumber(inputData.inputAsset) : '',
      inputData.currency
    ),
    [inputData.inputAsset, inputData.currency]
  );

  if (inputAsset) {
    localStorage.setItem('input_asset', inputAsset);
  }

  const { error } = calculationInfo;
  const isOperationDisabled =
    !isNil(calculation) && !calculation?.operationStatus?.enabled;

  useEffect(() => {
    const idx = setTimeout(() => {
      setInputData({ currency, inputAsset });
    }, DEBOUNCE_TIMER);
    return () => clearTimeout(idx);
  }, [currency, inputAsset]);

  useEffect(() => {
    if (!isEmpty(calculationInfo.data)) {
      setCalculation(calculationInfo.data);
      if (calculationInfo?.data?.limit) {
        localStorage.setItem(
          'limit',
          JSON.stringify(calculationInfo.data.limit)
        );
        validateForm();
      }
    }
  }, [calculationInfo]);

  return (
    <Container>
      <ExchangerWrapper>
        <ReplenishmentWrapper>
          <ReplenishmentHeader>
            <WbWalletBtn
              onClick={() => history.push(`/profile?tab=${WALLETS}`)}
              type="button"
            >
              {t('calculationView.wbWallet')}
            </WbWalletBtn>
          </ReplenishmentHeader>
          <Form>
            <ReplenishmentContainer>
              <DropdownInput
                {...props}
                value={inputAsset}
                label={t('calculationView.replenishWallet')}
                name="calculation.inputAsset"
                currencyType={currency}
                selectName="currency"
                isShowLimitRate={isShowLimitRate}
                setCurrencyPair={setCurrency}
                selectOptions={currencies?.data?.cryptoCurrencies.map(it => ({
                  value: it.currency,
                  label: getCurrencyLabel(it.currency),
                }))}
                isLimitErrorBottom
                ErrorComponent={
                  isShowLimitRate &&
                  (() => (
                    <LimitRateError
                      information={calculation}
                      errorLimit={errors.calculation}
                    />
                  ))
                }
              />
            </ReplenishmentContainer>
            <SubmitButton
              information={calculationInfo}
              calcError={errors.calculation}
              isSubmitting={isSubmitting}
              isTestingRequired={isTestingRequired}
              buttonName={t('converterCard.replenishment')}
            />
          </Form>
        </ReplenishmentWrapper>
        {isNotVerified && (
          <SystemInfoError>
            <div>
              {t('calculationView.errors.validationUserVerification', {
                operation: 'пополнения',
              })}
            </div>
          </SystemInfoError>
        )}
        {errors?.orderCreation?.message && (
          <SystemInfoError>
            <div>{errors.orderCreation.message}</div>
          </SystemInfoError>
        )}
        {error?.status && (
          <SystemInfoError>
            <div>{requestErrors[error.status]}</div>
          </SystemInfoError>
        )}
        {isOperationDisabled && (
          <SystemInfoError>
            <div>
              {t(`workTimeErrors.${calculation?.operationStatus?.status}`, {
                operation: 'пополнения',
              })}
            </div>
          </SystemInfoError>
        )}
        {isShowLimitRate && (
          <LimitContent>
            <LimitRate
              t={t}
              information={calculation}
              errorLimit={errors.calculation}
            />
          </LimitContent>
        )}
      </ExchangerWrapper>
    </Container>
  );
};

export default ReplenishmentCalculator;
